@mixin clear-fix {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }
    &::after {
        clear: both;
    }
}
@mixin unstyled-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
@mixin object-fit($options: ()) {
    
    $defaults: (
        object-fit: cover,
        object-position: null,
        display: block,
        width: 100%,
        height: false,
        height-large: false,
        //for pollyfill
        font-family: 'object-fit: cover',
    );

    $options: map-merge($defaults, $options);
    @if map-get($options, object-fit) != cover {
        $options: map-merge($options, (font-family: 'object-fit: ' + map-get($options, object-fit)));
    }
    overflow: hidden;
    img {
        
        @each $key, $value in (
            object-fit: object-fit,
            // object-position: object-position,
            display: display,
            width: width,
            height: height,
            //for use with ofi.browser.js pollyfill
            font-family: font-family,
        ) {
            @if map-get($options, $value) != false {
                #{$key}: map-get($options, $value);
            }
        }
        @if map-get($options, object-position) != null {
            $object-position-x: nth(map-get($options, object-position), 1);
            $object-position-y: center;
            @if length(map-get($options, object-position)) > 1 {
                $object-position-y: nth(map-get($options, object-position), 2);
            }
            object-position: var(--position-left, #{$object-position-x}) var(--position-top, #{$object-position-y});
        } @else {
            object-position: var(--position-left) var(--position-top);
        }
        @include breakpoint(large up) {
                @each $key, $value in (
                    height: height-large,
                ) {
                    @if map-get($options, $value) != false {
                        #{$key}: map-get($options, $value);
                    }
                }
            }
    }

}
@mixin sr-only {
    clip: rect(0 0 0 0); 
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

//Given a breakpoint map of columns, generates required nth-child selectors to create a checkerboard pattern
//content node styles are applied to a set of media queries ranges between each pair in the map
@mixin checker-selectors($item-selector, $per-row) {
    $keys: map-keys($per-row);
    #{$item-selector} {    
        @each $breakpoint, $columns in $per-row {
            $index: index($keys, $breakpoint);
            $selectors: ();
            @if $columns % 2 == 0 {
                @for $i from 1 through $columns / 2 {
                    $selectors: append($selectors, '&:nth-child(#{$columns * 2}n + #{$i * 2})');
                    $selectors: append($selectors, '&:nth-child(#{$columns * 2}n + #{($i * 2) + $columns - 1})');
                }
            } @else {
                $selectors: append($selectors, '&:nth-child(2n)');
            }
            #{join($selectors, (), $separator: comma)} {
                @include breakpoint($breakpoint up) {
                    @if index($keys, $breakpoint) < length($keys) {
                        $upper-bounds: nth($keys, $index + 1);
                        @if map-has-key($breakpoints, $upper-bounds) {
                            $upper-bounds: map-get($breakpoints, $upper-bounds);
                        }
                        @media screen and (max-width: #{-zf-bp-to-em($upper-bounds) - 0.00125}) {
                            @content;
                        }
                    } @else {
                        @content;
                    }
                }
            }
        }
    }
}


