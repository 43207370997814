@function get-input-label-absolute-props($props: ()) {
    @return map-merge((
        label-font-size: (14/$em) * 1rem,
        label-line-height: 1.2,
        label-x-offset: 0.4rem, 
        label-padding: .2rem,
        label-background-bleed: .125rem
    ), $props);
}
@mixin input-label-absolute-colors(
    $props: ()
) {
    $props: get-input-props($props, input-label-absolute input-colors);
    $label-selector: map-get($props, label-selector);

    #{$label-selector},
    .input-error {
        background-color: map-get($props, input-background-color);
        box-shadow: 0 map-get($props, label-background-bleed) * -1 map-get($props, input-background-color),
                    0 map-get($props, label-background-bleed) map-get($props, input-background-color);

    }
}
@mixin input-label-absolute-unset($props) {
    $props: get-input-props($props);
    $label-selector: map-get($props, label-selector);

    $border-width: get-border-value(map-get($props, input-border), width);

    display: block;
    flex-direction: row;

    #{$label-selector},
    .input-error {
        @include input-label-absolute-floating-text-unset;
        background-color: transparent;
        box-shadow: none;
    }
    #{$label-selector} {
        position: static;
        font-size: map-get($props, label-font-size);
        line-height: map-get($props, label-line-height);
    }
    .input-error {
        @include form-message-size;
        font-size: initial;
        &::after {
            left: 0;
        }
    }
}
@mixin input-label-absolute(
    $props: ()
) {
    $props: get-input-props($props, input-label-absolute);
    $label-selector: map-get($props, label-selector);

    $label-font-size: map-get($props, label-font-size);
    $label-x-offset: map-get($props, label-x-offset); 
    $label-padding: map-get($props, label-padding); 
    $input-border-radius: map-get($props, input-border-radius); 
    $label-line-height: map-get($props, label-line-height);
    @if unit($label-line-height) == '' {
        $label-line-height: $label-font-size * $label-line-height;
    }

    position: relative;
    display: flex;
    flex-direction: column;
    .button {
        align-self: center;
    }
    #{$label-selector},
    .input-error {
        @include input-label-absolute-floating-text(
            $label-font-size,
            $label-line-height,
            $label-x-offset,
            $label-padding,
            $input-border-radius
        );
    }
    #{$label-selector} {
        z-index: 4;
        order: -1;
        align-self: flex-start;
    }
    .input-error {
        $icon-size: $label-font-size;
        @include form-message-size(
            $line-height: $label-line-height,
            $icon-size: $icon-size,
            $icon-margin: $label-padding
        );
        z-index: 6;
        align-self: flex-end;
        padding-left: calc(#{$label-padding * 2} + #{$icon-size});
        &::after {
            left: $label-padding;
        }
    }
}
@mixin input-label-absolute-floating-text(
    $font-size,
    $line-height,
    $x-offset,
    $padding,
    $border-radius
) {
    @if unit($line-height) == '' {
        $line-height: $font-size * $line-height;
    }
    position: relative;
    padding: 0 $padding;
    margin-left: $x-offset;
    margin-right: $x-offset;
    border-radius: $border-radius;
    font-size: $font-size;
    line-height: $line-height;
    margin-top: -#{$line-height / 2};
    margin-bottom: -#{$line-height / 2};
}
@mixin input-label-absolute-floating-text-unset {
    z-index: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
}
