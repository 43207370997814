$baseline: 0.75rem !default;
$gutter: $baseline !default;
@mixin rte-content(
    $baseline: $baseline,
    $gutter: $baseline,
    $selector: '.rte',
    $headers: 'h2, h3',
    $list-baseline: $baseline * 2/3
) {
    #{$selector} {
        ul,
        ol,
        p,
        blockquote,
        hr,
        table {
            @extend %rte-element;
        }

        #{$headers} {
            @extend %rte-header;
        }

        %rte-element {
            margin-top: $baseline;
            margin-bottom: 0;
            &:first-child {
                margin-top: 0;
            }
        }

        %rte-header {
            margin-top: $baseline * 2;
            margin-bottom: 0;
            &:first-child {
                margin-top: 0;
            }
            & + * {
                margin-top: $baseline / 2;
            }
            & + %rte-header {
                margin-top: $baseline;
            }
        }

        ul,
        ol {
            padding-left: $gutter * 2;
            line-height: 1.25;
            li {
                margin-top: $list-baseline;
                &:first-child {
                    margin-top: 0;
                }
            }
            ul,
            ol {
                padding-left: $gutter;
                margin-top: $list-baseline;
                &:first-child {
                    margin-top: $list-baseline;
                }
            }
        }
        blockquote {
            @include typography('text-large');
            margin-left: $gutter * 4;
            margin-right: 0;
            position: relative;
            &::before {
                content: '“';
                font-size: 5em;
                line-height: 1;
                position: absolute;
                top: -.1em;
                right: 100%;
                margin-right: .1em;
                opacity: .7;
            }
        }
        table {
            th, td {
                padding: 0.5em;
            }
        }
        .media-container {
            width: 100%;
            position: relative;
            padding-bottom: percentage(9/16);
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    hr {
        border: none;
        border-top: 1px solid;
    }
}

@mixin rte-lists(
    $gutter: $baseline * 2,
    $bullet-style-placeholder: '%default-bullet',
    $number-style-placeholder: $bullet-style-placeholder,
    $selector: '.rte'
) {
    #{$selector} {    
        ul,
        ol {
            padding-left: 0;
            list-style: none;

            li {
                position: relative;
                padding-left: $gutter;
            }

            li::before {
                position: absolute;
                display: inline-block;
                left: 0;
            }
        }
        ol {
            counter-reset: orderedList;
            li::before {
                counter-increment: orderedList;
                content: counter(orderedList);
                @extend #{$number-style-placeholder};
            }
        }
        ul li::before {
            content: '•';
            @extend #{$bullet-style-placeholder};
        }
    }
    %default-bullet {
        width: $gutter;
        color: inherit;
        text-align: center;
        font-weight: $bold;
        transform: scale(0.8);
    }
}
