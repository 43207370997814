@import 'parent:';

@mixin sb-icon-phone($svg-color: $primary-color) {
    background-image: inline-svg('<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384" style="fill: #{$svg-color};"><path d="M362.667,266.667c-26.56,0-52.267-4.267-76.16-12.16c-7.36-2.347-15.787-0.64-21.653,5.227l-46.933,47.04c-60.48-30.72-109.867-80.107-140.587-140.48l46.933-47.147c5.867-5.867,7.573-14.293,5.227-21.653c-7.893-23.893-12.16-49.6-12.16-76.16C117.333,9.493,107.84,0,96,0H21.333C9.6,0,0,9.493,0,21.333C0,221.653,162.347,384,362.667,384c11.84,0,21.333-9.493,21.333-21.333V288C384,276.16,374.507,266.667,362.667,266.667z"/></svg>');
}
@mixin sb-icon-email($svg-color: $primary-color) {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667" style="fill: #{$svg-color};"><path d="M384,42.667H42.667c-23.573,0-42.453,19.093-42.453,42.667L0,341.333C0,364.907,19.093,384,42.667,384H384c23.573,0,42.667-19.093,42.667-42.667v-256C426.667,61.76,407.573,42.667,384,42.667z M384,128L213.333,234.667L42.667,128V85.333L213.333,192L384,85.333V128z"/></svg>');
}
@mixin sb-icon-address($svg-color: $primary-color) {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667" style="fill: #{$svg-color};"><path d="M213.333,0C130.88,0,64,66.88,64,149.333c0,112,149.333,277.333,149.333,277.333s149.333-165.333,149.333-277.333C362.667,66.88,295.787,0,213.333,0z M213.333,202.667c-29.44,0-53.333-23.893-53.333-53.333S183.893,96,213.333,96s53.333,23.893,53.333,53.333S242.773,202.667,213.333,202.667z"/></svg>');
}
@mixin sb-icon-arrow($svg-color: $primary-color){
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style="fill: #{$svg-color};"><rect x="5.4" y="5.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 9.1429 -7.3154)" width="16" height="3"/><rect x="11.9" y="8.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.6784 -4.6083)" width="3" height="16"/></svg>');
}
@mixin sb-icon-arrow-left($svg-color: $primary-color){
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style="fill: #{$svg-color}; transform: rotate(180deg); transform-origin: center;"><rect x="5.4" y="5.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 9.1429 -7.3154)" width="16" height="3"/><rect x="11.9" y="8.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.6784 -4.6083)" width="3" height="16"/></svg>');
}
@mixin sb-icon-arrow-down($svg-color: $primary-color) {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 451.847 451.847" enable-background="new 0 0 451.847 451.847"><path fill="#{$svg-color}" d="M225.923 354.706c-8.098 0-16.195-3.092-22.37-9.263L9.27 151.157c-12.36-12.36-12.36-32.397 0-44.75 12.354-12.355 32.388-12.355 44.748 0L225.923 278.32 397.83 106.413c12.358-12.354 32.39-12.354 44.743 0 12.365 12.354 12.365 32.392 0 44.75L248.293 345.45c-6.178 6.17-14.275 9.256-22.37 9.256z"/></svg>');
}
@mixin sb-icon-arrow-up($svg-color: $primary-color) {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 451.847 451.847" enable-background="new 0 0 451.847 451.847" style="transform: rotate(180deg); transform-origin: center;"><path fill="#{$svg-color}" d="M225.923 354.706c-8.098 0-16.195-3.092-22.37-9.263L9.27 151.157c-12.36-12.36-12.36-32.397 0-44.75 12.354-12.355 32.388-12.355 44.748 0L225.923 278.32 397.83 106.413c12.358-12.354 32.39-12.354 44.743 0 12.365 12.354 12.365 32.392 0 44.75L248.293 345.45c-6.178 6.17-14.275 9.256-22.37 9.256z"/></svg>');
}
@mixin sb-icon-user($svg-color) {
    background-image: inline-svg('<svg fill="#{$svg-color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path d="M18,.562a18,18,0,1,0,18,18A18,18,0,0,0,18,.562ZM18,7.53a6.387,6.387,0,1,1-6.387,6.387A6.387,6.387,0,0,1,18,7.53ZM18,32.5a13.908,13.908,0,0,1-10.633-4.95,8.092,8.092,0,0,1,7.149-4.34,1.776,1.776,0,0,1,.515.08,9.61,9.61,0,0,0,2.969.5,9.573,9.573,0,0,0,2.969-.5,1.776,1.776,0,0,1,.515-.08,8.092,8.092,0,0,1,7.149,4.34A13.908,13.908,0,0,1,18,32.5Z" transform="translate(0 -0.563)"/></svg>');
}
@mixin sb-icon-quote($svg-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style="fill:#{$svg-color};"><path d="M17.2,9.7C9.9,9.7,4,15.7,4,22.9s5.9,13.2,13.2,13.2c0.4,0,0.8,0.2,1,0.6c0.4,0.8,1.3,6-3.4,13.4c1.3,1.3,2.1,2.1,2.7,2.8c0.5,0.5,0.8,0.9,1.1,1.2c3-3,13.2-14,11.9-30.9C29.9,15.3,24.3,9.7,17.2,9.7z"/><path d="M59.9,23c-0.6-7.8-6.1-13.4-13.2-13.4c-7.3,0-13.2,5.9-13.2,13.2S39.4,36,46.7,36c0.4,0,0.8,0.2,1,0.6c0.4,0.8,1.3,6-3.4,13.4c1.3,1.3,2.1,2.1,2.7,2.8c0.5,0.5,0.9,0.9,1.2,1.2C51,51,61.2,40,59.9,23L59.9,23z"/></svg>');
}
