@import '@foundation/util/unit';
@import '@foundation/util/value';

$alert-color: #d50603 !default;
$warning-color: #f4783e !default;
$success-color: #a6b639 !default;
$required-color: #d50603 !default;

$input-props: () !default;

@import 'inputs/form-message';
@import 'inputs/button';
@import 'inputs/input-base';
@import 'inputs/input-checkbox';
@import 'inputs/input-label-absolute';
@import 'inputs/input-label-placeholder';
@import 'inputs/input-label-hybrid';
@import 'inputs/input-label-before';
@import 'inputs/input-list-table';
@import 'inputs/input-switch';
@import 'inputs/input-filter-buttons';
@import 'inputs/input-material';
@import 'inputs/input-select-search';
@import 'inputs/input-uploader';

@mixin input-all(
    $base-style: input-label-hybrid,
    $additional-styles: (
        '.label-placeholder': input-label-placeholder,
        '.switch': input-switch,
        '.checkbox-list li, .radio-buttons li': input-checkbox
    ),
    $features: (
        '.input-set': input-basic-fieldset,
        '.input fieldset': input-basic-fieldset,
        '.button': button-base,
        '.input-uploader-field': input-uploader,
    ),
    $props: (),
    $base-selector: '.input'
) {
    #{$base-selector} {
        @include apply-input-mixin(input-base, $props);
        @include apply-input-mixin(input-colors, $props);
        @each $mixin in $base-style {
            @include apply-input-mixin($mixin, $props);
            @include apply-input-mixin(#{$mixin + '-colors'}, $props);
        }
    }
    @each $selector, $mixins in $additional-styles {
        #{$selector} {
            @each $mixin in $base-style {
                @include apply-input-mixin(#{$mixin + '-unset'}, $props);
            }
            @each $mixin in $mixins {
                @include apply-input-mixin($mixin, $props);
                @include apply-input-mixin(#{$mixin + '-colors'}, $props);
            }
        }
    }
    @each $selector, $mixins in $features {
        #{$selector} {
            @each $mixin in $mixins {
                @include apply-input-mixin($mixin, $props);
                @include apply-input-mixin($mixin + '-colors', $props);
            }
        }
    }
}

@function get-input-props($props: (), $input-style: base) {
    //defaults -> input-style(s) defaults -> site input-props -> local props
    $defaults: (
        vertical-margin: $baseline,
        input-padding: $baseline * .75,
        input-border: 1px solid,
        label-font-size: 1rem,
        label-line-height: 1.25rem,
        input-font-size: 1rem,
        input-line-height: 1.5rem,
        input-border-radius: .125rem,
        icon-size: 1rem,
        icon-button-size: .5rem,
        field-selector: '.input-field',
        label-selector: '.input-label'
    );

    $props: map-merge($input-props, $props);

    @each $style in $input-style {
        @if $style == input-label-top {
            $props: get-input-label-top-props($props);
        } @else if $style == input-colors {
            $props: get-input-colors($props);
        } @else if $style == input-label-absolute {
            $props: get-input-label-absolute-props($props);
        } @else if $style == input-label-absolute-colors {
            $props: get-input-label-absolute-colors($props);
        } @else if $style == input-label-hybrid {
            $props: get-input-label-hybrid-props($props);
        } @else if $style == input-fancy-fieldset {
            $props: get-input-fancy-fieldset-props($props);
        } @else if $style == input-label-placeholder {
            $props: get-input-label-placeholder-props($props);
        } @else if $style == input-checkbox {
            $props: get-input-checkbox-props($props);
        } @else if $style == input-checkbox-colors {
            $props: get-input-checkbox-colors($props);
        } @else if $style == input-switch-colors {
            $props: get-input-switch-colors($props);
        } @else if $style == button-base {
            $props: get-button-base-props($props);
        } @else if $style == input-label-before {
            $props: get-input-label-before-props($props);
        } @else if $style == input-material {
            $props: get-input-material-props($props);
        } @else if $style == input-material-fieldset {
            $props: get-input-material-fieldset-props($props);
        } @else if $style == input-select-search {
            $props: get-input-select-search-props($props);
        } @else if $style == input-select-search-colors {
            $props: get-input-select-search-colors($props);
        }
    }
    $props: map-merge($defaults, $props);

    @return $props;
}

// calculates value with a unit for line height based on font size
@function get-input-line-height($props: (), $type: input) {
    $props: get-input-props($props);

    $line-height: map-get($props, #{$type}-line-height);
    $font-size: map-get($props, #{$type}-font-size);

    @if unit($line-height) == '' {
        @return $font-size * $line-height;
    }
    @return $line-height;
}

@function get-input-height($props: (), $calc: true) {
    $props: get-input-props($props);
    $line-height: get-input-line-height($props);
    $input-padding: map-get($props, input-padding);

    @if $calc == false {
        @return $line-height + get-side($input-padding, top) + get-side($input-padding, bottom);
    }
    @return calc(#{$line-height}
            + #{get-side($input-padding, top)}
            + #{get-side($input-padding, bottom)});
}

//ripped from foundation
@function text-inputs($types: ()) {
  $return: ();

  $all-types:
    text
    password
    date
    datetime
    datetime-local
    month
    week
    email
    number
    search
    tel
    phone
    time
    url
    color;

  @if not has-value($types) {
    $types: $all-types;
  }

  @each $type in $types {
    $return: append($return, unquote('[type=\'#{$type}\']'), comma);
  }

  @return $return;
}

@mixin form-base(
    $border-color: false,
    $border-focus-color: false,
    $text-color: false,
    $placeholder-text-color: false
) {
    $props: get-input-props((), input-colors);
    @if not $border-color {
        $border-color: map-get($props, input-border-color);
    }
    @if not $border-focus-color {
        $border-focus-color: map-get($props, input-border-focus-color);
    }
    @if not $text-color {
        $text-color: map-get($props, text-color);
    }
    @if not $placeholder-text-color {
        $placeholder-text-color: map-get($props, placeholder-text-color);
    }

    input,
    textarea {
        border: 1px solid $border-color;
        color: $text-color;
        box-shadow: none; //ff validation override
        &:focus {
            border: 1px solid $border-focus-color;
        }
    }
    #{text-inputs()} {
        outline: 0;
    }
    ::placeholder {
        color: $placeholder-text-color;
    }
    :-ms-input-placeholder {
        color: $placeholder-text-color;
    }
    .input-error {
        @include input-error;
    }
    .message {
        @include form-message;
        &.warning {
            color: $warning-color;
            &::after {
                @include sb-icon-exclamation($warning-color);
            }
        }
        &.success {
            &::after {
                @include sb-icon-check($success-color);
            }
        }
        &.error {
            color: $alert-color;
            &::after {
                @include sb-icon-close($alert-color);
            }
        }
    }
}

@mixin apply-input-mixin($mixins, $props: ()) {
    @each $mixin in $mixins {
        @if $mixin == input-base {
            @include input-base($props);
        } @else if $mixin == input-label-top {
            @include input-label-top($props);
        } @else if $mixin == input-label-absolute {
            @include input-label-absolute($props: $props);
        } @else if $mixin == input-label-absolute-unset {
            @include input-label-absolute-unset($props: $props);
        } @else if $mixin == input-label-placeholder {
            @include input-label-placeholder($props: $props);
        } @else if $mixin == input-switch {
            @include input-switch($props);
        } @else if $mixin == button-base {
            @include button-base($props);
        } @else if $mixin == input-colors {
            @include input-colors($props: $props);
        } @else if $mixin == input-label-absolute-colors {
            @include input-label-absolute-colors($props);
        } @else if $mixin == input-label-placeholder-colors {
            @include input-label-placeholder-colors($props);
        } @else if $mixin == input-switch-colors {
            @include input-switch-colors($props: $props);
        } @else if $mixin == input-filter-buttons {
            @include input-filter-buttons($props: $props);
        } @else if $mixin == input-label-hybrid {
            @include input-label-hybrid($props: $props);
        } @else if $mixin == input-label-hybrid-colors {
            @include input-label-hybrid-colors($props: $props);
        } @else if $mixin == input-label-hybrid-unset {
            @include input-label-hybrid-unset($props: $props);
        } @else if $mixin == input-checkbox {
            @include input-checkbox ($props: $props);
        } @else if $mixin == input-checkbox-colors {
            @include input-checkbox-colors ($props: $props);
        } @else if $mixin == input-basic-fieldset {
            @include input-basic-fieldset ($props: $props);
        } @else if $mixin == input-fancy-fieldset {
            @include input-fancy-fieldset ($props: $props);
        } @else if $mixin == input-fancy-fieldset-colors {
            @include input-fancy-fieldset-colors ($props: $props);
        } @else if $mixin == input-fancy-fieldset-unset {
            @include input-fancy-fieldset-unset ($props: $props);
        } @else if $mixin == input-label-before {
            @include input-label-before ($props: $props);
        } @else if $mixin == input-label-before-colors {
            @include input-label-before-colors ($props: $props);
        } @else if $mixin == input-material {
            @include input-material ($props: $props);
        } @else if $mixin == input-material-colors {
            @include input-material-colors ($props: $props);
        } @else if $mixin == input-material-unset {
            @include input-material-unset ($props: $props);
        } @else if $mixin == input-material-fieldset {
            @include input-material-fieldset ($props: $props);
        } @else if $mixin == input-material-fieldset-colors {
            @include input-material-fieldset-colors ($props: $props);
        } @else if $mixin == input-select-search {
            @include input-select-search ($props: $props);
        } @else if $mixin == input-select-search-colors {
            @include input-select-search-colors ($props: $props);
        } @else if $mixin == input-uploader {
            @include input-uploader ($props: $props);
        } @else if $mixin == input-uploader-colors {
            @include input-uploader-colors ($props: $props);
        }
    }
}

@mixin input-group(
    $props: (),
    $selector: "*",
    $grow: true
) {
    $props: get-input-props($props);
    $border-width: get-border-value(map-get($props, input-border), width);
    display: flex;
    flex-direction: row;
    margin-top: map-get($props, vertical-margin);
    .input {
        margin-top: 0;
        // flex-grow: 1;
    }
    .button {
        flex-shrink: 0;
    }
    & > #{$selector} {
        width: auto;
        @if $grow {
            flex-grow: 1;
        }
        // margin-top: map-get($props, vertical-margin) / 2;
    }
    & > #{$selector}:first-of-type:not(:last-of-type) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        & > input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    & > #{$selector}:last-of-type:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        & > input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    & > #{$selector}:not(:last-of-type) {
        // border-right: 0;
        margin-right: $border-width * -1;
        & > input {
            border-right: 0;
        }
    }
    & > #{$selector}:not(:last-of-type):not(:first-of-type) {
        border-radius: 0;
        & > input {
            border-radius: 0;
        }
    }
}


//not ie11 safe
@mixin input-reset($adjacency: false) {
    select,
    input,
    textarea,
    label,
    fieldset,
    legend {
        z-index: unset;
        position: unset;
        display: unset;
        width: unset;
        min-width: unset;
        height: unset;
        min-height: unset;
        margin: unset;
        padding: unset;
        border: unset;
        outline: unset;
        opacity: unset;
        background: unset;
        overflow: unset;
        &::before,
        &::after {
            content: unset;
            display: unset;
        }
    }
    @if $adjacency == true {
        select,
        textarea,
        input {
            & + label {
                z-index: unset;
                position: unset;
                display: unset;
                width: unset;
                min-width: unset;
                height: unset;
                min-height: unset;
                margin: unset;
                padding: unset;
                border: unset;
                outline: unset;
                opacity: unset;
                background: unset;
                overflow: unset;
                &::before,
                &::after {
                    content: unset;
                    display: unset;
                }
            }
        }
    }
}
