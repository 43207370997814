@import 'parent:';

$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 768px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1700px,
);

$mobile-breakpoint-up: medium;
$mobile-breakpoint-down: smallplus;

// foundation caps print breakpoints to large, which would make some sense
// however, we also use breakpoints in m-ui which doesn't do this, so removing for consistency
$print-breakpoint: xxlarge;

//doesn't actually change the header, just used for banner offset
$header-height: (
    small: $baseline * 6.5,
    medium: $baseline * 10,
);

$header-logo-height: (
    small: $baseline * 2,
    medium: $baseline * 3
);

$outer-gutters: (
    small: $gutter * 1.5,
    medium: $gutter * 2,
    large: $gutter * 4,
);

$content-gutters: (
    small: $gutter / 2,
    smallplus: $gutter
);

$section-padding: (
    small: $baseline * 3,
    medium: $baseline * 4,
    xlarge: $baseline * 6,
    // xxlarge: $baseline * 10
);

$banner-height: (
    small: 0,
    medium: 18rem
);
$banner-height-home: (
    small: 18rem,
    medium: (420/$em) * 1rem,
    xlarge: (520/$em) * 1rem
    // xxlarge: (720/$em) * 1rem
);
$banner-clip-path: null;
// $banner-clip-ratio: 12;
$banner-clip-ratio: false;

$site-width: 100vw;
$site-columns: 12;
$content-width: 800px;

$teal: #00A79D;
$teal-dark: #006a5d;
$teal-light: #b9e2e0;
$teal-white: #f0f9f8;

$lime: #CEC42D;
$lime-light: #e7e3a2;
$lime-dark: #9e940d;

$green: #8CC63E;
$green-dark: #5a881c;

$orange: #F16521;
$purple: #91268F;
$yellow: #FEC10D;
$red: #e23f17;
$red-light: #ef8e77;
$blue: #0066AB;

$gray50: #f8f8f8;
$gray100: #f0f0f0;
$gray200: #e5e5e5;
$gray300: #d4d4d4;
$gray400: #b0b0b0;
$gray500: #8f8f8f;
$gray600: #686868;
$gray700: #545454;
$gray800: #363636;
$gray900: #161616;

$off-white: $gray50;
$light-gray: $gray400;
$medium-gray: $gray600;
$dark-gray: $gray800;

$alert-color: $red;
$required-color: $red;

$primary-color: $teal;

$secondary-color: $teal;

$text-color-regular: $medium-gray;
$text-color-dark: $dark-gray;

$anchor-color: $teal;
$anchor-color-hover: $teal-dark;

$anchor-color-white: $text-color-white;
$anchor-color-white-hover: $lime-light;

$banner-text-color: $text-color-dark;
$banner-background-color: $white;
$banner-background-opacity: .35;
$content-background-color: $off-white;
$footer-background-color: $dark-gray;
$footer-contact-background-color: $teal;

$body-font-family: 'museo-sans', sans-serif;
$header-font-family: 'museo-slab', sans-serif;

$light: 100;
$normal: 300;
$semibold: 500;
$bold: 700;

$available-icons: facebook twitter email phone instagram;

$input-props: (
    vertical-margin: $baseline * 1.5,
    input-border-radius: (2/$em) * 1rem,
    input-line-height: 1.25em,
    input-padding: .75em .75em .625em,
    input-border: (2/$em) * 1rem,
    input-background-color: $gray100,
    input-border-focus-color: $anchor-color,
    button-min-width: 8em,
    button-padding: .75em 1em,
    button-border: 0
);

$button-colors: (
    base: (
        background: $anchor-color,
        text: $text-color-white,
        border: $anchor-color,
        background-hover: $anchor-color-hover,
        text-hover: $text-color-white,
        border-hover: $anchor-color-hover,
    ),
    fill: (
        background: $anchor-color,
        text: $text-color-white,
        border: $anchor-color,
        background-hover: $anchor-color-hover,
        text-hover: $text-color-white,
        border-hover: $anchor-color-hover,
    ),
    text-only: (
        background: transparent,
        text: inherit,
        border: transparent,
        background-hover: transparent,
        text-hover: $anchor-color,
        border-hover: transparent,
    ),
    white: (
        background: $white,
        text: $anchor-color,
        border: $white,
        background-hover: $white,
        text-hover: $anchor-color,
        border-hover: $white,
    ),
);

$typography: (
    header-base: (
        font-weight: $light,
        font-family: $header-font-family,
        line-height: 1.25,
        color: inherit,
    ),
    text-base: (
        font-weight: $normal,
        line-height: 1.5,
        font-family: $body-font-family,
        color: $text-color-regular,
    ),
    header-e: (
        base: header-base,
        font-size: #{12/$em}rem,
        line-height: #{16/$em}rem,
        font-weight: $normal,
        xlarge: (
            font-size: #{14/$em}rem,
            line-height: #{18/$em}rem,
        ),
    ),
    header-d: (
        base: header-base,
        font-size: #{18/$em}rem,
        line-height: #{24/$em}rem,
        font-weight: $semibold,
        xlarge: (
            font-size: #{20/$em}rem,
            line-height: #{24/$em}rem,
        ),
    ),
    header-c: (
        base: header-base,
        font-size: #{20/$em}rem,
        line-height: #{24/$em}rem,
        xlarge: (
            font-size: #{24/$em}rem,
            line-height: #{30/$em}rem,
        ),
    ),
    header-b: (
        base: header-base,
        font-size: #{24/$em}rem,
        line-height: #{28/$em}rem,
        xlarge: (
            font-size: #{30/$em}rem,
            line-height: #{36/$em}rem,
        ),
    ),
    header-a: (
        base: header-base,
        font-size: #{30/$em}rem,
        line-height: #{36/$em}rem,
        xlarge: (
            font-size: #{48/$em}rem,
            line-height: #{60/$em}rem,
        ),
    ),
    text-small: (
        base: text-base,
        font-size: #{12/$em}rem,
        line-height: #{18/$em}rem,
        xlarge: (
            font-size: #{14/$em}rem,
            line-height: #{20/$em}rem,
        )
    ),
    text-medium: (
        base: text-base,
        font-size: #{16/$em}rem,
        line-height: 1.5,
        xlarge: (
            font-size: #{18/$em}rem,
            // line-height: #{24/$em}rem,
        ),
    ),
    text-large: (
        base: text-base,
        color: inherit,
        font-size: #{16/$em}rem,
        line-height: #{30/$em}rem,
        smallplus: (
            font-size: #{18/$em}rem,
        ),
        xlarge: (
            font-size: #{24/$em}rem,
            line-height: #{42/$em}rem,
        ),
    ),
    text-xlarge: (
        base: text-base,
        color: inherit,
        font-size: #{18/$em}rem,
        line-height: #{30/$em}rem,
        xlarge: (
            font-size: #{30/$em}rem,
            line-height: #{48/$em}rem,
        ),
    ),
);

$article-props: (
    compact-breakpoint: small,
    // content-width: $content-width,
    image-size: (
        small: $baseline * 4,
        smallplus: $baseline * 6,
        xlarge: $baseline * 9
    ),
    summary-typography: text-medium,
    // head-padding: 0,
);

$article-icons-props: (
    // size: 1em,
    // background-size: 1em,
    hover-color: $anchor-color
);

$email-baseline: 12px;

$email-header-padding-small: $email-baseline * 3 $email-baseline * 2 $email-baseline * 2.5;
$email-header-padding: $email-header-padding-small;

$email-content-padding-small: $email-baseline * 2;
$email-content-padding: $email-content-padding-small; 

$email-background-color: $gray100;
$email-footer-color: $footer-background-color;

$email-button-palette: fill;
$email-button-props: (
    padding: $email-baseline $email-baseline * 2
);
$email-typography: (
    header-base: (
        font-family: $header-font-family,
        line-height: 1.2,
        color: $text-color-regular,
    ),
    header-e: (
        base: header-base,
        font-size: 12px,
        font-weight: $semibold,
    ),
    header-d: (
        base: header-base,
        font-size: 16px,
        font-weight: $semibold,
    ),
    header-c: (
        base: header-base,
        font-size: 18px,
        font-weight: $light,
    ),
    header-b: (
        base: header-base,
        font-size: 20px,
        font-weight: $light,
    ),
    header-a: (
        base: header-base,
        font-size: 22px,
        font-weight: $light,
    ),
    text-base: (
        font-family: $body-font-family,
        font-weight: $normal,
        line-height: 1.5,
    ),
    text-small: (
        base: text-base,
        font-size: 14px,
    ),
    text-medium: (
        base: text-base,
        font-size: 16px,
    ),
    text-large: (
        base: text-base,
        font-size: 18px,
    ),
);