@mixin page-layout-login {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 2fr;
    justify-items: center;
    min-height: 100vh;
    background-color: $off-white;
    padding: $gutter;
    .sb-login {
        grid-column: 1;
        grid-row: 2;
    }
}

@mixin login-form {
    width: 100%;
    max-width: 30rem;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    border-radius: (2/$em) * 1rem;
    .login-header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $baseline * 3 $baseline * 2 $baseline * 2.5;
        .icon-padlock {
            order: -1;
            margin-right: $baseline;
            width: (30/$em) * 1rem;
            height: (30/$em) * 1rem;
        }
        .login-back {
            position: absolute;
            top: $baseline;
            left: $baseline;
            line-height: 1;
            font-size: (12/$em) * 1rem;
        }
    }
    .login-brand-logo {
        max-width: 10rem;
        width: 100%;
        line-height: 0;
    }
    .login-body {
        padding: $baseline * 2;
        padding-top: $baseline * 2 - map-get(get-input-props(), vertical-margin);
    }
    
    @include breakpoint(medium up) {
        display: grid;
        grid-template-columns: 2fr 5fr;
        max-width: 45rem;
        .login-header {
            flex-direction: column;
            justify-content: flex-start;
            padding: $baseline * 3 $baseline * 3 $baseline * 5;
            .icon-padlock {
                order: 2;
                margin-right: 0;
                margin-top: $baseline * 2;
                max-width: $baseline * 8;
                width: 100%;
                height: auto;
            }
            .login-back {
                top: auto;
                left: $baseline * 1.5;
                bottom: $baseline * 1.5;
            }
        }
        .login-body {
            padding: $baseline * 3;
            padding-top: $baseline * 3 - map-get(get-input-props(), vertical-margin);
        }
    }
    
    .login-form {
        & > * {
            margin-top: map-get(get-input-props(), vertical-margin);
        }
        .button-cont {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            .login-button {
                flex-shrink: 0;
            }
            .password-link {
                flex-grow: 1;
                margin-right: $baseline;
                line-height: 1.25;
            }
            & > * {
                margin-top: map-get(get-input-props(), vertical-margin);
            }
        }
        &.reset-success {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }   
}

@mixin login-form-colors(
    $header-background-color: $primary-color,
    $header-text-color: $text-color-white,
    $header-anchor-color: $anchor-color-white,
    $header-anchor-color-hover: $anchor-color-white-hover,
    $body-background-color: $white,
    $body-text-color: $text-color-regular,
    $body-anchor-color: $anchor-color,
    $body-anchor-color-hover: $anchor-color-hover
) {
    .login-header {
        background-color: $header-background-color;
        color: $header-text-color;
        a {
            color: $header-anchor-color;
            &:hover,
            &:focus {
                color: $header-anchor-color-hover;
            }
        }
    }
    .login-body {
        background-color: $body-background-color;
        color: $body-text-color;
        a:not(.login-button) {
            color: $body-anchor-color;
            &:hover,
            &:focus {
                color: $body-anchor-color-hover;
            }
        }
    }
}