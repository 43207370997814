@import 'parent:';

@mixin page-layout-article-page {
    @include page-layout-center;
    .header-banner {
        .hero-page {
            margin-bottom: 0;
            h1.title {
                @include typography(header-b);
            }
        }
        .article-meta {
            @include breakout($center-layout-columns) {
                @include grid-column(nth($value, 1), nth($value, 2), true);
            }
            @include grid-row(4);
            @include breakout($section-padding) {
                margin-bottom: $value;
            }
        }
        .pager.page_image img {
            opacity: .2;
        }
    }
}

@mixin article-grid {
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
    .item-wrapper {
        @include breakout($section-padding) {
            margin-bottom: $value / 2;
        }
    }
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                600px: 2,
                1200px: 3
            ),
            $gutter: $baseline,
            $baseline: $baseline,
            $shrink-wrap: true,
        );
    }
    .item {
        border-bottom: 6px solid currentColor;
        position: relative;
        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }
    }
    .head {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $gray200;
        transition: background-color .2s;
        .image {
            @include object-fit;
            display: flex;
            background-color: rgba($content-background-color, .7);
            img {
                mix-blend-mode: multiply;
            }
        }
        .text-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: $baseline * 1.5;
            & > * {
                margin-top: $baseline / 2;
            }
        }
        .title {
            @include typography(header-d);
        }
        .meta {
            order: -1;
            @include typography(text-small);
            text-transform: uppercase;
            margin-top: 0;
        }
        .categories,
        .date-posted {
            display: inline-block;
            line-height: 1.25;
        }
        .categories {
            margin-right: .5rem;
        }
        .category {
            margin-right: .5rem;
            &:not(:last-child)::after {
                content: ',',
            }
        }
        .date-posted {
            .icon {
                margin-right: .5em;
            }
        }
        .more-button {
            display: inline-block;
            margin-top: $baseline;
            font-weight: $semibold;
            font-size: (14/$em) * 1rem;
            text-transform: uppercase;
            color: inherit;
        }
        .file-statistics {
            display: block;
            font-size: (14/$em) * 1rem;
        }
    }
    .item.link {
        .head {
            @include grid;
            @include grid-template-rows(1fr 1fr);
            &::before {
                @include grid-column(1);
                @include grid-row(1, 2);
            }
            .image {
                @include grid-column(1);
                @include grid-row(1);
            }
            .text-group {
                @include grid-column(1);
                @include grid-row(2);
                background-color: rgba($content-background-color, .95);
                & > * {
                    color: $text-color-regular;
                }
            }
            .more-button {
                padding-top: $baseline;
                margin-top: auto;
                color: inherit;
            }
            .title {
                color: $text-color-regular;
            }
        }
    }
    .item.page,
    .item.download,
    .item.none {
        .head {
            .image,
            .text-group {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .text-group {
                justify-content: flex-end;
                background: linear-gradient(
                    to bottom,
                    transparent,
                    transparent 40%,
                    rgba(0, 0, 0, 0.9));
                & > * {
                    color: $text-color-white;
                }
            }
            .more-button {
                color: inherit;
            }
            .title {
                @include typography(header-b);
            }
            .title {
                //sass messes up code order if this shares rule with typgraphy mixin
                color: $text-color-white;
                // line-height: 1;
            }
        }
    }
    .item:nth-child(4n + 1) {
        color: $orange;
    }
    .item:nth-child(4n + 2) {
        // color: $purple;
        color: #bc57ba;
    }
    .item:nth-child(4n + 3) {
        color: $green;
    }
    .item:nth-child(4n + 4) {
        // color: $blue;
        color: #4796cc;
    }
    .head-link {
        color: inherit;
        display: block;
    }
    .head-link:hover,
    .head-link:focus {
        .head {
            background-color: currentColor;
        }
    }
}
