.header {
    z-index: 29;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include grid;
    @include grid14;
    .logo img {
        width: auto;
        @include breakout($header-logo-height) {
            height: $value;
        }
    }
}
.header-inner {
    @include grid-column(1, 14);
    // @include breakout($header-height) {
    //     min-height: $value;
    // }
    @include breakout($outer-gutters) {
        padding-left: $value;
        padding-right: $value;
    }
    position: relative;
    display: flex;
    align-items: center;
    padding-top: $baseline / 2;
    padding-bottom: $baseline / 2;
    
    & > * {
        @include breakout($content-gutters, smallplus) {
            margin-left: $value;
            margin-right: $value;
        }
    }

    a {
        color: $text-color-regular;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .is-active > a {
        color: $anchor-color;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .logo {
        padding: $baseline * 1.5 0;
    }
    .button.button {
        font-size: (14rem / $em);
    }
    .mobile-menu-expand {
        margin: 0;
    }
    @include dropdown-menu-bubble($selector: '.chat-dialog');
    @include dropdown-menu-align($align: center, $selector: '.chat-dialog');
    .chat-inbox-client {
        position: absolute;
        top: 100%;
        // margin-left: $gutter;
        // margin-right: $gutter;
        @include breakout($outer-gutters) {
            left: $value;
            right: $value;
        }
    }
    .chat-dialog {
        position: relative;
        height: 100vh;
        padding: 0;
        @include breakout($header-height) {
            max-height: calc(100vh - #{$value} - #{$gutter} - 10px);
        }
        &::before {
            left: auto;
            right: 62px;
        }
    }
    @include breakpoint($mobile-breakpoint-down down) {
        .chat-inbox-client {
            z-index: 40;
            display: flex;
            position: fixed;
            top: 18px;
            left: 18px;
            bottom: 18px;
            right: 18px;
            margin: 0;
            pointer-events: none;
        }
        .chat-dialog {
            height: auto;
            max-height: none;
            flex-grow: 1;
            pointer-events: auto;
        }
        .chat-dialog::before {
            left: auto;
            right: 32px;
        }
        .chat-inbox-button {
            margin-left: auto;
            margin-right: 40px;
        }
        .adminChatButton-MuiIconButton-root {
            font-size: 1.75rem;
        }
    }
    @include breakpoint($mobile-breakpoint-up up) {
        @include grid-column(1, 12, true);
        justify-content: flex-end;
        padding: $baseline * 2 0 0;
        .logo {
            align-self: flex-start;
            padding: $baseline / 2 0;
        }
        .primary-navigation {
            margin-left: auto;
        }
        .chat-inbox-client {
            left: auto;
            right: 0;
            width: 512px;
        }
        .chat-dialog {
            margin-top: 10px;
            height: 400px;
        }
    }
    @media print {
        .chat-inbox-button,
        .mobile-menu-expand {
            visibility: hidden;
        }
    }
}
.header-inner.minimal {
    padding-top: $baseline * 2.5;
    padding-bottom: 0;
    justify-content: flex-start;
    .logo {
        padding: 0;
    }
}
.primary-navigation {
    a {
        display: flex;
    }
    .sub-menu {
        display: none;
        padding-left: $baseline * 2;
        a {
            &::before {
                content: '-';
                display: inline-block;
                width: 1em;
                height: 1em;
                margin-right: .5em;
                text-align: center;
            }
        }    
    }
    .nav-social {
        display: flex;
        .icon {
            font-size: 1.25em;
        }
        a {
            padding: $baseline / 2;
        }
    }
    .nav-user {
        display: flex;
        align-items: center;
        font-size: 1rem;
        & > * {
            margin-left: $baseline;
        }
        .button-session {
            margin-left: $baseline * 2;
        }
    }
    .nav-primary,
    .nav-extra {
        font-size: (20/$em) * 1rem;
        font-weight: $medium;
    }

    @include breakpoint($mobile-breakpoint-down down) {
        display: flex;
        flex-direction: column;
        background-color: $off-white;
        & > * {
            margin-top: $baseline;
        }
        .nav-user {
            order: 1;
            margin-right: $baseline * 2;
        }
        .button.button-session {
            flex-grow: 1;
            order: -1;
            // border-radius: 0;
        }
        .search-cont {
            order: 2;
            margin-left: $baseline * 2;
            margin-right: $baseline * 2;
        }
        .nav-primary,
        .nav-extra {
            order: 3;
            a {
                display: block;
                padding: $baseline $baseline * 2;
                text-align: center;
            }
        }
        .nav-primary {
            margin-top: 0;
        }
        .sub-menu a {
            padding: $baseline / 2 $baseline * 2 $baseline / 2 0;
        }
        .is-active > .sub-menu {
            display: block;
        }
        .nav-social {
            order: 4;
            // justify-content: center;
            padding: $baseline 0 $baseline * 2;
            margin-left: $baseline * 1.5;
            .icon {
                font-size: 1.5em;
            }
        }
    }

    @include breakpoint($mobile-breakpoint-up up) {
        @include grid;
        .nav-primary,
        .nav-extra {
            display: flex;
            margin-top: $baseline;
            line-height: $baseline * 2;
            li {
                margin-left: $baseline;
                margin-right: $baseline / -2;
            }
            a {
                padding: 0 $baseline / 2;
            }
        }
        .nav-extra {
            @include grid-row(1);
            @include grid-column(1);
            @include grid-align-self(center);
        }
        .nav-primary {
            @include grid-row(1);
            @include grid-column(2);
            @include grid-align-self(center);
        }
        .search-cont {
            @include grid-row(1);
            @include grid-column(3);
            @include grid-align-self(center);
            margin-left: $baseline;
        }
        .nav-user {
            @include grid-row(2);
            @include grid-column(1, 3);
            @include grid-justify-self(end);
            margin-left: $baseline * 1.5;
            margin-top: $baseline;
        }
        .button-session {
            font-size: 1rem;
        }        
    }
    @media print {
        visibility: hidden;
    }
}

@include header-flyin(
    $sizes: (
        $mobile-breakpoint-down: 75%,
        small: 100%
    ),
    $underlay-color: rgba($white, 0.8),
    $menu-icon-background-color: $off-white,
    $menu-button-x-offset: $gutter / 2
);
.mobile-menu-expand {
    @include breakpoint($mobile-breakpoint-up up) {
        display: none;
    }
}
#admin-chat-desktop-button {
    @include breakpoint($mobile-breakpoint-down down) {
        display: none;
    }
}
#admin-chat-mobile-button {
    @include breakpoint($mobile-breakpoint-up up) {
        display: none;
    }
}
.mobile-menu-expand-icon {
    @include menu-button($text-color-regular);
    .open & {
        @include menu-button-open;
    }
}