@function get-input-switch-colors($props: ()) {
    $defaults: map-merge((
        switch-color: $white,
        switch-background-color: $anchor-color,
    ), $props);

    @return map-merge((
        switch-unchecked-background-color: lighten(desaturate(map-get($defaults, switch-background-color), 70%), 40%),
    ), $defaults);
}

@mixin input-switch-colors (
    $props: ()
) {
    $props: get-input-props($props, input-switch-colors);
    $label-selector: map-get($props, label-selector);
    .switch-slider {
        background-color: map-get($props, switch-unchecked-background-color);
        &::after {
            background-color: map-get($props, switch-color);
        }
    }
    [type="checkbox"]:checked ~ #{$label-selector} .switch-slider {
        background-color: map-get($props, switch-background-color);
    }
}
@mixin input-switch (
    $props: ()
) {
    $props: get-input-props($props);
    $label-selector: map-get($props, label-selector);
    $text-size: map-get($props, input-font-size);

    input[type="checkbox"] {
        position: absolute;
        width: 0px;
        height: 0px;
        margin: 0;
        overflow: hidden;
        opacity: 0;
    }
    #{$label-selector} {
        display: flex;
        align-items: flex-start;
        margin: 0;
        line-height: 1.4;
        font-size: $text-size;
        cursor: pointer;
    }
    .switch-slider {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        width: 2.4em;
        height: 1.4em;
        margin-right: .5em;
        border-radius: .7em;
        transition: background-color .2s ease;
        &::before {
            content: '';
            flex-grow: 0;
            width: 0px;
            transition: flex-grow .2s ease;
        }
        &::after {
            content: '';
            flex-shrink: 0;
            width: 1em;
            height: 1em;
            margin: .2em;
            border-radius: 50%;
        }
    }
    [type="checkbox"]:checked ~ label .switch-slider {
        &::before {
            flex-grow: 1;
        }
    }
    label {
        &::before {
            display: none;
        }
    }
    &.label-before {
        .switch-slider {
            margin-right: 0;
            margin-left: .5em;
        }
    }
}
