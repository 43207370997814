@mixin form-message (
    $line-height: 1.25em,
    $icon-size: 1em,
    $icon-margin: .4em,
    $icon-color: $text-color-regular
) {
    @include form-message-size($line-height, $icon-size, $icon-margin);
    position: relative;
    &::after {
        @include sb-icon-exclamation($icon-color);
        content: '';
        position: absolute;
        left: 0;
        // background-size: 1em 1em;
        background-position: center;
        background-repeat: no-repeat;
    }
}

@mixin form-message-size (
    $line-height: 1.25em,
    $icon-size: 1em,
    $icon-margin: .4em
) {
    padding-left: calc(#{$icon-size} + #{$icon-margin});
    min-height: max($icon-size, $line-height);
    line-height: $line-height;
    &:after {
        top: calc((#{$line-height} - #{$icon-size}) / 2);
        width: $icon-size;
        height: $icon-size;
    }
}

@mixin input-warning (
    $line-height: 1.25em,
    $icon-size: 1em,
    $icon-margin: .4em
) {
    @include form-message($line-height, $icon-size, $icon-margin, $icon-color: $warning-color);
    color: $warning-color;
}
@mixin input-success (
    $line-height: 1.25em,
    $icon-size: 1em,
    $icon-margin: .4em
) {
    @include form-message($line-height, $icon-size, $icon-margin);
    &::after {
        @include sb-icon-check($success-color);
    }
}

@mixin input-error (
    $line-height: 1.25em,
    $icon-size: 1em,
    $icon-margin: .4em
) {
    @include form-message($line-height, $icon-size, $icon-margin);
    color: $alert-color;
    &::after {
        @include sb-icon-close($alert-color);
    }
}
