@mixin overview {
    @include grid-column(1, 14);
    @include grid;
    @include grid14;
    @include item-wrapper-full-width;
    // @include breakout($section-padding) {
    //     padding-top: $value;
    //     padding-bottom: $value;
    // }
    // margin-top: 0;
    // margin-bottom: 0;
    & > * {
        @include grid-column(1, 12, true);
        // @include content-columns;
        @include breakpoint(xlarge up) {
            @include grid-column(2, 10, true);
        }
        // @include breakpoint(xxlarge up) {
        //     @include grid-column(3, 9, true);
        // }
    }
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2,
                large: 3
            ),
            $gutter: 0px,
            $baseline: $baseline * 1.5,
            $shrink-wrap: true
        );
        justify-content: center;;
    }
    .item-wrapper {
        @include grid-column(1, 14);
        @include breakout($section-padding) {
            margin-bottom: $value / 2;
        }
    }
}

@mixin overview-item(
    $image-size: (
        small: (60/$em) * 1rem,
        smallplus: (80/$em) * 1rem,
        large: (120/$em) * 1rem,
    ),
    $vertical-margin: (
        small: $baseline,
        large: $baseline * 1.5
    ),
) {
    .item {
        display: flex;
    }
    .head {
        @include grid;
        @include grid-template-columns(auto 1fr);
        flex-grow: 1;
    }
    .text-group,
    .image,
    .button {
        @include item-gutters;
    }
    .image {
        @include grid-column(1);
        @include grid-row(1, 2);
        @include grid-justify-self(start);
        @include breakout($image-size) {
            width: $value;
            height: $value;
        }
        display: flex;
        flex-shrink: 0;
    }
    .text-group {
        @include grid-row(1);
        @include grid-column(2);
        flex-grow: 1;
        .title {
            @include typography(header-b);
        }
        .summary,
        .description {
            @include breakout($vertical-margin) {
                margin-top: $value;
            }
            max-width: 18em;
        }
    }
    .button {
        @include grid-row(2);
        @include grid-column(2);
        @include breakout($vertical-margin) {
            margin-top: $value;
        }
        @include button-reset;
        @include button-text-only;
    }
    @include breakpoint(460px up) {
        .image {
            @include grid-justify-self(center);
        }
    }
    @include breakpoint(medium up) {
        .head {
            display: flex;
            flex-direction: column;
        }
        .text-group {
            margin-top: $baseline * 2;
        }
    }
    a.head-link {
        color: inherit;
        &:hover,
        &:focus {
            .button {
                @include button-color(text-only, true);
            }
        }
    }
}