@import 'parent:';

@mixin callout-svg-palettes {
    .item:nth-child(4n + 1) {
        svg {
            fill: $orange;
        }
    }
    .item:nth-child(4n + 2) {
        svg {
            fill: $purple;
        }
    }
    .item:nth-child(4n + 3) {
        svg {
            fill: $green;
        }
    }
    .item:nth-child(4n + 4) {
        svg {
            fill: $blue;
        }
    }
}

@mixin callouts-points {
    @include grid-column(1, 14);
    @include grid;
    @include grid14;
    @include make-grid-rows(2);
    @include item-wrapper-full-width;
    & > * {
        @include content-columns;
        @include breakpoint(xlarge up) {
            @include grid-column(2, 11, true);
        }
        @include breakpoint(xxlarge up) {
            @include grid-column(3, 9, true);
        }
    }
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2,
                large: 3
            ),
            $gutter: 0px,
            $baseline: $baseline * 1.5,
            $shrink-wrap: true
        );
    }
    .item-wrapper {
        @include grid-column(1, 14);
        @include breakout($section-padding) {
            margin-bottom: $value / 2;
        }
    }
}

@mixin callouts-points-item(
    $image-size: (
        small: (30/$em) * 1rem,
        large: (48/$em) * 1rem,
    ),
    $vertical-margin: (
        small: $baseline,
        large: $baseline * 1.5
    ),
) {
    @include callout-svg-palettes;
    .item {
        @include grid;
        @include subgrid;
    }
    .text-group,
    .head-image {
        @include item-gutters;
    }
    .head-image {
        @include grid-column(1, 2);
        @include grid-justify-self(start);
        @include breakout($image-size) {
            width: $value;
            height: $value;
        }
        display: flex;
    }
    .image svg {
        width: 100%;
        height: 100%;
    }
    .text-group {
        @include grid-column(3, 10);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .title {
            @include typography(header-b);
        }
        .description {
            @include breakout($vertical-margin) {
                margin-top: $value;
            }
            max-width: 18em;
            flex-grow: 1;
        }
    }
    .button {
        @include breakout($vertical-margin) {
            margin-top: $value;
        }
        @include button-reset;
        @include button-text-only;
    }
    @include breakpoint(460px up) {
        .head-image {
            @include grid-justify-self(center);
        }
    }
    @include breakpoint(large up) {
        .item {
            display: flex;
            flex-direction: column;
        }
        .text-group {
            margin-top: $baseline * 2;
        }
    }
}

@mixin callouts-clients {
    @include grid-column(1, 14);
    @include breakout($outer-gutters) {
        padding-left: $value;
        padding-right: $value;
    }
    .item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        @include breakout($content-gutters) {
            & > * {
                width: calc(100% - #{$value});
            }
        }
        @include breakout($section-padding) {
            margin-bottom: $value / 2;
        }
        &:empty {
            display: none;
        }
    }
    .items {
        @include flex-columns(
            $per-row: (
                small: 2,
                smallplus: 2,
                medium: 4,
                large: 5,
                xlarge: 6
            ),
            $baseline: $baseline * 2,
            $gutter: (
                small: $gutter,
                smallplus: $gutter * 2
            ),
            $shrink-wrap: true,
        );
        // margin-top: $baseline * -1;
        justify-content: center;
    }
}

@mixin callouts-clients-item(
    $height: (
        small: $baseline * 3.5,
        large: $baseline * 7
    )
) {
    .image {
        @include object-fit((object-fit: contain));
        @include breakout($height) {
            height: $value;
        }
        display: flex;
    }
}

@mixin callouts-testimonials {
    @include grid-column(1, 14);
    @include item-wrapper-full-width;
    background-color: $teal-white;
    @include breakout($section-padding) {
        .item {
            margin-top: $value / 2;
        }
    }
    .items {
        @include grid;
        @include grid14;
        @include make-grid-rows(2);
        .item {
            @include grid-column(1, 12, true);
            @include item-gutters;
        }
        @include breakpoint(460px up) {
            .item {
                @include grid-column(2, 11, true);
            }
        }
        @include breakpoint(medium up) {
            .item {
                @include grid-row(1);
                @include grid-column(2, 5, true);
                &:nth-child(2n) {
                    @include grid-column(8, 5, true);
                }
            }
        }
        @include breakpoint(large up) {
            .item {
                @include grid-column(2, 4, true);
                &:nth-child(2n) {
                    @include grid-column(8, 4, true);
                }
            }
        }
        @include breakpoint(xxlarge up) {
            .item {
                @include grid-column(3, 4, true);
                &:nth-child(2n) {
                    @include grid-column(8, 4, true);
                }
            }
        }
    }
}
@mixin callouts-testimonials-item {
    .item,
    .head {
        display: flex;
    }
    .text-group::before,
    .image {
        width: $baseline * 2.5;
        height: $baseline * 2.5;
        @include breakpoint(xlarge up) {
            width: $baseline * 3;
            height: $baseline * 3;
        }
    }
    .no-image .text-group::before {
        @include sb-icon-user($teal-light);
        content: '';
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .image {
        @include object-fit;
        display: flex;
        img {
            border-radius: 50%;
        }
    }
    .head {
        flex-direction: column;
        position: relative;
        width: 100%;
        padding-top: $baseline * 1.5;
        padding-bottom: $baseline * 1.5;
        &::before {
            @include sb-icon-quote($teal-white);
            z-index: 1;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1rem;
            right: -1rem;
            background-color: $white;
            border-radius: .75rem;
            text-align: right;
            color: $teal-white;
            background-size: 4em 4em;
            background-position: right $baseline top $baseline / 2;
            background-repeat: no-repeat;
        }
        & > * {
            z-index: 2;
            position: relative;
        }
        @include breakpoint(medium up) {
            padding-top: $baseline * 2;
            padding-bottom: $baseline * 2;
            &::before {
                left: $baseline * -2;
                right: $baseline * -2;
            }
        }
        @include breakpoint(large up) {
            &::before {
                right: $baseline * -6;
            }
        }
        .summary {
            line-height: 1.75;
            font-style: italic;
            margin-bottom: auto;
            max-width: 36ch;
            @include breakpoint(xlarge up) {
                font-size: (20/$em) * 1rem;
            }
        }
    }
    .text-group {
        @include grid;
        @include grid-template-columns(auto 1fr);
        @include grid-template-rows(auto 1fr);
        margin-top: $baseline * 2;
        .image,
        &::before {
            @include grid-row(1, 2);
            @include grid-column(1);
            margin-right: $baseline * 3/4;
        }
        .title {
            @include grid-row(1);
            @include grid-column(2);
            @include typography(header-c);
        }
        .subtitle {
            @include grid-row(2);
            @include grid-column(2);
            @include grid-align-self(center);
            @include typography(header-e);
            margin-top: $baseline / 3;
        }
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 2.5;
            .image,
            &::before {
                margin-right: $baseline;
            }
        }
    }
}

@mixin callouts-text {
    .item-wrapper {
        // @include breakout($section-padding) {
        //     margin-bottom: $value / 2;
        // }
        .title {
            max-width: none;
        }
        @include breakout($content-gutters) {
            margin-left: $value;
            margin-right: $value;
        }
    }
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2
            ),
            $gutter: $content-gutters
        );
    }
    .item {
        .title {
            @include typography(text-medium);
        }
    }
    .head {
        display: flex;
        align-items: flex-start;
        &::before {
            content: '';
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 1rem;
        }
        .text-group {
            max-width: 35rem;
            @include breakpoint(medium up) {
                padding-right: 2rem;
            }
        }
    }
    .item:nth-child(4n + 1) .head::before {
        background-color: $green;
    }
    .item:nth-child(4n + 2) .head::before {
        background-color: $blue;
    }
    .item:nth-child(4n + 3) .head::before {
        background-color: $purple;
    }
    .item:nth-child(4n + 4) .head::before {
        background-color: $orange;
    }
}