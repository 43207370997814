$button-colors: (
    base: (
        background: transparent,
        text: $text-color-regular,
        border: initial,
        background-hover: $anchor-color-hover,
        text-hover: $text-color-white,
        border-hover: $anchor-color-hover,
    ),
    text-only: (
        background: transparent,
        text: $anchor-color,
        border: transparent,
        background-hover: transparent,
        text-hover: $anchor-color-hover,
        border-hover: transparent,
    ),
    fill: (
        background: $anchor-color,
        text: $text-color-white,
        border: $anchor-color,
        background-hover: $anchor-color-hover,
        text-hover: $text-color-white,
        border-hover: $anchor-color-hover,
    ),
) !default;

@function get-button-base-props($props: ()) {
    $defaults: get-input-props($props);
    @return map-merge((
        button-min-width: 6rem,
        button-padding: map-get($defaults, input-padding),
        button-border: map-get($defaults, input-border),
    ), $defaults);
}


@mixin button-base(
    $props: ()
) {
    $props: get-input-props($props, button-base);

    $border-radius: map-get($props, input-border-radius);
    $font-size: map-get($props, input-font-size);
    $line-height: map-get($props, input-line-height);
    $padding: map-get($props, button-padding);
    $min-width: map-get($props, button-min-width);

    $border-width: get-border-value(map-get($props, button-border), width);
    @if $border-width == 0 {
        $border-width: 0px;
    }


    display: inline-block;
    min-width: $min-width;
    -webkit-appearance: none;
    line-height: $line-height;
    text-align: center;
    font-size: $font-size;
    cursor: pointer;
    padding-top: calc(#{get-side($padding, top)} - #{$border-width});
    padding-right: calc(#{get-side($padding, right)} - #{$border-width});
    padding-bottom: calc(#{get-side($padding, bottom)} - #{$border-width});
    padding-left: calc(#{get-side($padding, left)} - #{$border-width});
    border-width: $border-width;
    border-style: solid;
    border-radius: $border-radius;
    transition: background-color .2s ease,
                color .2s ease,
                opacity .2s ease,
                border-color .2s ease;
    &.disabled,
    &:disabled {
        opacity: .5;
        pointer-events: none;
    }
}
@function get-button-colors($key) {
    $colors: ();
    @if (type-of($key) == map or type-of($key) == list) {
        $colors: $key;
    } @else {
        $colors: map-get($button-colors, $key);
    }
    @return $colors;
}
@mixin button-color($key, $hover-only: false) {
    $colors: get-button-colors($key);
    @if $hover-only {  
        background-color: map-get($colors, background-hover);
        color: map-get($colors, text-hover);
        border-color: map-get($colors, border-hover);
    } @else {
        background-color: map-get($colors, background);
        color: map-get($colors, text);
        border-color: map-get($colors, border);
        &:hover,
        &:focus {
            background-color: map-get($colors, background-hover);
            color: map-get($colors, text-hover);
            border-color: map-get($colors, border-hover);
        }
    }
}
@mixin button-icon(
    $icon-size: 1.2em,
    $icon-margin: .4em,
    $icon-position: left center,
    $icon-background-size: false,
    $hide-text: false,
    $baseline-offset: 0rem,
    $icon-selector: false
) {
    $icon-type: psuedo;
    @if $icon-selector {
        $icon-type: element;
    } @else {
        $icon-selector: '&::before, &::after';
    }

    $icon-position-x: nth($icon-position, 1);
    $icon-position-y: center;
    @if length($icon-position) > 1 {
        $icon-position-y: nth($icon-position, 2);
    }
    display: inline-flex;
    justify-content: flex-start;
    @if $icon-position-x == left {
        flex-direction: row;
    }
    @if $icon-position-x == right {
        flex-direction: row-reverse;
    }
    @if $icon-position-y == top {
        align-items: flex-start;
    } @else if $icon-position-y == bottom {
        align-items: flex-end;
    } @else {
        align-items: center;
    }
    @if $hide-text {
        display: block;
        position: relative;
        width: $icon-size;
        height: $icon-size;
        min-width: 0;
        padding: 0;
        text-indent: 100%;
        overflow: hidden;
        white-space: nowrap;
    }
    #{$icon-selector} {
        @if $icon-type == psuedo {
            content: '';
        }
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: $icon-size;
        height: $icon-size;
        @if $icon-position-y == center {
            margin-top: calc((#{$icon-size} / -2) + #{$baseline-offset / 2});
            margin-bottom: calc((#{$icon-size} / -2) - #{$baseline-offset / 2});
        } @else if $icon-position-y == bottom {
            margin-top: calc(#{$baseline-offset} - #{$icon-size});
            margin-bottom: $baseline-offset * -1;
        } @else {
            margin-top: $baseline-offset;
            margin-bottom: -#{$icon-size};
        }
        @if $icon-background-size {
            background-size: $icon-background-size $icon-background-size;
        } @else {
            background-size: contain;
        }
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity .2s ease;
        @if $hide-text {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
    #{$icon-selector} {
        @if not $hide-text {        
            @if $icon-position-x == left {
                margin-left: 0;
                margin-right: $icon-margin;
            } @else {
                margin-left: $icon-margin;
                margin-right: 0;
            }
        }
    }
    @if $icon-type == psuedo {
        &::after {
            z-index: 1;
            order: -1;
            opacity: 0;
            @if not $hide-text {
                @if $icon-position-x == left {
                    margin-left: 0;
                    margin-right: -#{$icon-size};
                } @else {
                    margin-left: -#{$icon-size};
                    margin-right: 0;
                }
            }
        }
        &:hover,
        &:focus {
            &::after { opacity: 1; }
        }
    }
}
