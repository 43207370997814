//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'slideshow:mixins';
@import 'forms:mixins';
@import 'site:page-mixins';
@import 'site:item-mixins';

@import 'mixins';

.lister.callouts-points {
    @include callouts-points;
    @include callouts-points-item;
}

.lister.callouts-clients {
    @include callouts-clients;
    @include callouts-clients-item;
}

.lister.callouts-testimonials {
    @include callouts-testimonials;
    @include callouts-testimonials-item;
}

.lister.callouts-alternating {
    @include grid-column(1, 12, true);
    @include breakpoint(medium up) {
        @include grid-column(1, 14);
    }
    @include callouts-alternating(
        $columns: (
            medium: (1 5, 6 8),
            large: (2 5, 8 6),
            xlarge: (2 5, 8 5),
            xxlarge: (3 4, 8 5),
        ),
        $flip-columns: (
            medium: (2 8, 10 5),
            large: (3 6, 9 5),
            xlarge: (3 5, 9 5),
            xxlarge: (4 5, 9 4),
        ),
        $image-height: auto,
        $item-gutter: $content-gutters,
        $item-margin: (
            small: $baseline * 4,
            medium: 0
        ),
        // $item-margin: $section-padding,
        $item-baseline: 0,
        $item-inner-baseline: $baseline * 2,
        $content-grid-override: 14,
        $shrink-wrap: false,
    );
    // @include breakout($section-padding) {
    //     margin-top: $value / 2;
    // }
    overflow: hidden;
    .item {
        .image {
            @include object-fit((object-fit: contain));
            max-height: $baseline * 35;
        }
    }
    @include breakpoint(medium up) {
        margin-top: 0;
        margin-bottom: 0;
        .item {
            @include breakout($section-padding) {
                padding-top: $value;
                padding-bottom: $value;
            }
            .head-image {
                @include grid-align-self(start);
            }
            .text-group {
                @include grid-align-self(center);
            }
            &:nth-child(even) {
                background-color: $teal-white;
            }
        }
    }
}

.lister.callouts-text {
    @include callouts-text;
}
