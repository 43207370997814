@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/breakpoint';
@import '@depot/typography';

@import 'site:item-mixins';
@import 'mixins';

// @include form-base(
//     // $text-color: $text-color-white,
//     $border-color: map-get(get-input-props((), input-colors), input-border-color),
//     $border-focus-color: map-get(get-input-props((), input-colors), input-border-focus-color)   
// );

.site {
    @include input-all(
        $base-style: input-material,
        $additional-styles: (
            '.switch': input-switch,
            '.checkbox-list li, .radio-buttons li': input-checkbox
        ),
    );
    .button {
        @include button;
    }
    .button.text-only {
        @include button-reset;
        @include button-text-only;
    }
    // .input-password {
    //     $material-props: get-input-props((), input-material input-colors);
    //     background-color: map-get($material-props, input-background-color);
    //     border-top-left-radius: map-get($material-props, input-border-radius);
    //     border-top-right-radius: map-get($material-props, input-border-radius);
    //     .button {
    //         z-index: 4;
    //         align-self: stretch;
    //         margin-top: (get-input-line-height($material-props, $type: label) / 2 + map-get($material-props, label-background-bleed)) * -1;
    //         padding: map-get($material-props, input-padding);
    //         padding-top: calc(
    //             #{get-side(map-get($material-props, input-padding), top)}
    //             + #{get-input-line-height($material-props, $type: label) / 2}
    //             + #{map-get($material-props, label-background-bleed)}
    //         );
    //         line-height: map-get($material-props, input-line-height);
    //         font-size: map-get($material-props, input-font-size);
    //         color: map-get($material-props, text-color);
    //         background-color: transparent;
    //         box-shadow: 0 (2/$em) * 1em 0 map-get($material-props, input-border-color);
    //     }
    // }

    //normally in form-base, but that isn't included due to mui conflicts
    .input-error {
        @include input-error;
    }
    .message {
        @include form-message;
        &.warning {
            color: $warning-color;
            &::after {
                @include sb-icon-exclamation($warning-color);
            }
        }
        &.success {
            &::after {
                @include sb-icon-check($success-color);
            }
        }
        &.error {
            color: $alert-color;
            &::after {
                @include sb-icon-close($alert-color);
            }
        }
    }
}

.contact-form {
    .button {
        margin-top: $baseline * 2;
    }
    .recaptcha-custom-terms {
        font-size: (11/$em) * 1rem;
        margin-top: $baseline * 1.5;
        color: $light-gray;
    }
}

.item-group.form-paper {
    @include paper;
    padding: $baseline * 2;
    & > * {
        margin-top: $baseline * 2;
    }
    h1.title,
    h2.title {
        @include typography(header-c);
        margin-top: 0;
    }
    .description {
        @include typography(text-medium);
        margin-top: 0;
    }
}

.item-group.request-form {
    .message {
        margin-bottom: $baseline;
    }
    form > .input:first-child {
        margin-top: 0;
    }
    .button {
        margin-top: $baseline * 2;
    }
    .radio-buttons li,
    .checkbox-list li {
        @include apply-input-mixin(input-checkbox, (
            checkbox-font-size: (18/$em) * 1rem,
            label-margin: $baseline * 2/3
        ));
    }
}
