@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/flex-columns';

@import 'site:item-mixins';
@import 'site:page-mixins';
@import 'avatars:mixins';
@import 'forms:mixins';


.avatar {
    @include avatar($background-color: $green);
    .avatar-circle {
        background-color: $green;
        img {
            clip-path: circle(45%);
        }
    }
}

.user-info-details .avatar {
    @include avatar-size(2rem);
}

.avatar-uploader {
    @include avatar-uploader($size: (
        small: $baseline * 12,
        460px: $baseline * 9,
        medium: $baseline * 12,
        xlarge: $baseline * 16
    ));
    @include breakpoint(medium down) {
        .image-uploader-browse label {
            top: 40%;
        }
    }
}

.controller.my-profile {
    max-width: $content-width;
    .message {
        margin-bottom: $baseline;
    }
    .my-profile-fields {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2
            ),
            $baseline: map-get(get-input-props(), vertical-margin) / 2,
            $gutter: map-get(get-input-props(), vertical-margin) / 2,
            $shrink-wrap: true
        );
    }
}

.layout-profile-profile {
    .controller.my-avatar,
    .controller.my-profile {
        @include breakout($content-gutters) {
            margin-left: $value;
            margin-right: $value;
        }
    }
    .controller.my-avatar {
        @include grid-justify-self(center);
    }
    @include breakpoint(460px up) {
        .controller.my-avatar {
            @include grid-row(1);
            @include grid-column(1, 3, true);
        }
        .controller.my-profile {
            @include grid-row(1);
            @include grid-column(4, 9, true);
            .button.submit {
                align-self: start;
            }
        }
    }
    @include breakpoint(large up) {
        .controller.my-avatar {
            @include grid-column(2, 2, true);
        }
        .controller.my-profile {
            @include grid-column(4, 8, true);
        }
    }
    @include breakpoint(xxlarge up) {
        .controller.my-avatar {
            @include grid-column(3, 2, true);
        }
        .controller.my-profile {
            @include grid-column(5, 8, true);
        }
    }
}