html {
    scroll-behavior: smooth;
    // background-color: $dark-gray;
}

html.navigation-open {
    @include modal-open;
}

.site {
    display: flex;
    flex-direction: column;
    //to keep fixed element positioning when mui adds body padding for modals
    position: relative;
}

.content {
    flex-grow: 1;
    background-color: $content-background-color;
}

@include page-layout-base;

.layout-not-found-page {
    .item-group.sitemap {
        display: none;
    }
}

.layout-people {
    .content {
        display: flex;
        background-color: $off-white;
    }
    .item-root {
        flex-grow: 1;
        @include breakout($header-height) {
            padding-top: $value + $baseline * 2;
        }
        @include breakout($section-padding) {
            padding-bottom: $value;
        }
    }
}

.layout-activate {
    .content {
        display: flex;
        background-color: $off-white;
    }
    .item-root {
        // @include breakout($header-height) {
        //     margin-top: $value;
        // }
        @include breakout($section-padding) {
            padding: $value 0;
        }
        margin-top: $baseline * 6;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
}

.content > *:first-child:not(.page-layout):not(.item-root):not(.page-layout-full) {
    @include breakout($header-height) {
        margin-top: $value + $baseline * 2;
    }
}

.page-layout-full {
    @include page-layout-full;
}

.layout-request-form-page {
    @include page-layout-center;
    .content {
        background-color: $off-white;
    }
    .group.form-paper {
        @include grid-justify-self(center);
        width: 100%;
        max-width: 600px;
    }
}

.page-request-thank-you {
    @include page-layout-center;
    .content {
        background-color: $off-white;
    }
}
