@function get-input-colors($props: ()) {
    //defaults -> local props -> cascaded defaults -> local props
    $props: map-merge((
        input-background-color: $white,
        input-background-disabled-color: $light-gray,
        input-border-color: $light-gray,
        input-border-focus-color: $text-color-regular,
        input-border-alert-color: $alert-color,
        text-color: $text-color-regular,
        placeholder-text-color: $text-color-light
    ), $props);
    $props: map-merge((
        input-background-focus-color: map-get($props, input-background-color),
        label-text-color: map-get($props, text-color),
        icon-color: rgba(map-get($props, text-color), .6),
        icon-button-color: map-get($props, text-color),
        icon-button-hover-color: map-get($props, input-border-focus-color),
    ), $props);
    @return map-merge((
        legend-text-color: map-get($props, label-text-color)
    ), $props);
}
@mixin input-base(
    $props: ()
) {
    $props: get-input-props($props);
    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);

    $vertical-margin: map-get($props, vertical-margin);
    $input-padding: map-get($props, input-padding);
    $input-border: map-get($props, input-border);
    $label-font-size: map-get($props, label-font-size);
    $label-line-height: map-get($props, label-line-height);
    $input-font-size: map-get($props, input-font-size);
    $input-line-height: map-get($props, input-line-height);
    $input-border-radius: map-get($props, input-border-radius);
    $icon-size: map-get($props, icon-size);
    $icon-button-size: map-get($props, icon-button-size);
    $select-icon-size: map-get($props, select-icon-size);

    $line-height: get-input-line-height($props);
    $label-line-height: get-input-line-height($props, $type: label);

    $border-width: get-border-value($input-border, width);
    @if $border-width == 0 {
        $border-width: 0px;
    }
    $height: get-input-height($props);

    margin-top: $vertical-margin;

    #{$label-selector} {
        display: block;
        font-size: $label-font-size;
        line-height: $label-line-height;
    }

    #{$field-selector} {
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding-top: calc(#{get-side($input-padding, top)} - #{$border-width});
        padding-right: calc(#{get-side($input-padding, right)} - #{$border-width});
        padding-bottom: calc(#{get-side($input-padding, bottom)} - #{$border-width});
        padding-left: calc(#{get-side($input-padding, left)} - #{$border-width});
        background-position: 
            right calc(#{get-side($input-padding, right)} - #{$border-width})
            top calc(#{get-side($input-padding, top)} - #{$border-width});
        background-size: $line-height $line-height;
        background-repeat: no-repeat;
        border: $input-border;
        border-radius: $input-border-radius;
        font-size: $input-font-size;
        line-height: $input-line-height;
        text-align: left;
        -webkit-appearance: none;
        -moz-appearance: none;
        transition: background-color .2s ease,
                    box-shadow .2s ease,
                    border .2s ease;
        &:focus {
            outline: 0;
        }
    }

    .input-icon {
        z-index: 3;
        position: relative;
        top: get-side($input-padding, top);
        right: get-side($input-padding, right);
        display: flex;
        align-items: center;
        justify-content: center;
        width:  $icon-size;
        height: $input-line-height;
        margin-bottom: $input-line-height * -1;
        margin-left: auto;
    }
    .input-icon-button {
        svg {
            font-size: $icon-button-size;
        }
        &:hover,
        &:focus {
            outline: none;
        }
    }

    &.required > label::after,
    &.required legend::after {
        content: "*";
        display:inline;
        vertical-align: middle;
        line-height: 0;
        margin-left: .4em;
        color: $required-color;
    }

    #{$field-selector} {
        height: $height;
    }
    textarea#{$field-selector} {
        min-height: $height;
        resize: vertical;
        &[rows] {
            height: auto;
        }
    }
    .input-icon ~ #{$field-selector} {
        padding-right: calc(#{$icon-size} + #{get-side($input-padding, right) * 1.5});
    }
    select#{$field-selector} {
        @include sb-icon-arrow-dropdown($text-color-regular);
        width: auto;
        padding-right: calc(#{$icon-size} + #{get-side($input-padding, right) * 1.5});
        background-size: $icon-button-size $icon-button-size;
        background-position: right calc(#{get-side($input-padding, right)} - #{$border-width} + (#{$icon-size} - #{$icon-button-size})/2) center;
        background-repeat: no-repeat;
    }
    select::-ms-expand {
        display: none;
    }

    // XXX Workaround for chrome not firing autofill events.
    @keyframes onAutoFillStart {
        from {/**/}
        to {/**/}
    }

    @keyframes onAutoFillCancel {
        from {/**/}
        to {/**/}
    }

    input {
        &:-webkit-autofill {
            // Expose a hook for JavaScript when auto fill is shown.
            // JavaScript can capture 'animationstart' events
            animation-name: onAutoFillStart;
            transition: background-color 0.3s ease-in-out 0s;
        }

        &:not(:-webkit-autofill) {
            // Expose a hook for JS onAutoFillCancel
            // JavaScript can capture 'animationstart' events
            animation-name: onAutoFillCancel;
        }
    }
    // XXX End workaround.
}
@mixin input-colors(
    $props: ()
) {
    $props: get-input-props($props, input-colors);
    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);

    color: map-get($props, text-color);
    ::placeholder {
        color: map-get($props, placeholder-text-color);
    }
    :-ms-input-placeholder {
        color: map-get($props, placeholder-text-color) !important;
    }
    #{$field-selector} {
        background-color: map-get($props, input-background-color);
        border-color: map-get($props, input-border-color);
        &:focus {
            background-color: map-get($props, input-background-focus-color);
            border-color: map-get($props, input-border-focus-color);
        }
        &:disabled,
        &[readonly] {
            background-color: map-get($props, input-background-disabled-color);
        }
    }
    #{$label-selector} {
        color: map-get($props, label-text-color);
    }
    legend {
        color: map-get($props, legend-text-color);
    }
    
    select#{$field-selector} {
        @include sb-icon-arrow-dropdown(map-get($props, text-color));
    }
    &.has-error-message #{$field-selector} {
        border-color: map-get($props, input-border-alert-color);
        &:focus {
            border-color: map-get($props, input-border-focus-color);
        }
    }
    .input-icon svg {
        fill: map-get($props, icon-color);
    }
    .input-icon-button {
        svg {
            fill: map-get($props, icon-button-color);
        }
        &:hover,
        &:focus {
            svg {
                fill: map-get($props, icon-button-hover-color);
            }
        }
    }
}

@mixin input-basic-fieldset($props: ()) {
    $props: get-input-props($props, input-label-top);

    $vertical-margin: map-get($props, vertical-margin);
    $label-font-size: map-get($props, label-font-size);
    $label-line-height: map-get($props, label-line-height);
    $label-margin: map-get($props, label-margin);

    @if index(nth(&, 1), '.input') {
        $vertical-margin: 0;
    }
    
    legend {
        display: block;
        width: 100%;
        font-size: $label-font-size;
        line-height: $label-line-height;
        margin: 0;
        padding: 0;
        background-color: transparent;
    }
    legend + * {
        margin-top: $label-margin;
    }
    margin: 0;
    margin-top: $vertical-margin;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
    &.required legend::after {
        content: "*";
        display:inline;
        vertical-align: middle;
        line-height: 0;
        margin-left: .4em;
        color: $required-color;
    }

    & + .input-error,
    & > .input-error {
        margin-top: $label-margin;
        align-self: flex-start;
        left: 0;
    }
}
@function get-input-fancy-fieldset-props($props: ()) {
    @return map-merge(get-input-label-top-props($props), get-input-label-absolute-props($props));
}
@mixin input-fancy-fieldset-unset($props: ()) {
    legend,
    .input-error {
        background-color: transparent;
        box-shadow: none;
    }
    & > .input-error {
        @include form-message-size;
        padding-right: 0;
        border-radius: 0;
        font-size: initial;
        &::after {
            left: 0;
        }
    }
}
@mixin input-fancy-fieldset-colors($props: ()) {
    $props: get-input-props($props, input-label-absolute input-colors);
    background-color: map-get($props, input-background-color);
    border-color: map-get($props, input-border-color);
    &:focus-within {
        border-color: map-get($props, input-border-focus-color);
    }
    legend {
        color: map-get($props, legend-text-color);
    }
    legend,
    .input-error {
        background-color: map-get($props, input-background-color);
        box-shadow: 0 map-get($props, label-background-bleed) * -1 map-get($props, input-background-color),
                    0 map-get($props, label-background-bleed) map-get($props, input-background-color);
    }
    &.has-error-message {
        border-color: map-get($props, input-border-alert-color);
        &:focus {
            border-color: map-get($props, input-border-focus-color);
        }
    }
}
@mixin input-fancy-fieldset($props: ()) {
    // $props: get-input-props($props, input-label-absolute input-label-top);
    $props: get-input-props($props, input-fancy-fieldset);

    $input-padding: map-get($props, input-padding);
    $vertical-margin: map-get($props, vertical-margin);
    $input-border: map-get($props, input-border);
    $input-border-radius: map-get($props, input-border-radius);
    $label-line-height: map-get($props, label-line-height);
    $label-font-size: map-get($props, label-font-size);
    $label-x-offset: map-get($props, label-x-offset);
    $label-margin: map-get($props, label-margin);
    $label-padding: map-get($props, label-padding);

    @if unit($label-line-height) == '' {
        $label-line-height: $label-font-size * $label-line-height;
    }
    $border-width: get-border-value($input-border, width);

    position: relative;
    margin: 0;
    @if index(nth(&, 1), '.input') {
        margin-top: -#{$label-line-height / 2};
    } @else {
        margin-top: calc(#{$vertical-margin} - #{$label-line-height / 2});
    }
    border: $input-border;
    border-radius: $input-border-radius;
    padding-top: calc(#{get-side($input-padding, top)} - #{$border-width});
    padding-right: calc(#{get-side($input-padding, right)} - #{$border-width});
    padding-bottom: calc(#{get-side($input-padding, bottom)} - #{$border-width});
    padding-left: calc(#{get-side($input-padding, left)} - #{$border-width});
    transition: background-color .2s ease,
                box-shadow .2s ease,
                border .2s ease;
    & > .input {
        margin-top: $vertical-margin / 2;
    }
    & > .input-type-radio {
        margin-top: $label-margin;
    }
    legend,
    & > .input-error {
        font-size: $label-font-size;
        border-radius: $input-border-radius;
    }
    legend {
        line-height: $label-line-height;
        margin-left: calc(#{$label-x-offset} - #{get-side($input-padding, left)});
        margin-right: calc(#{$label-x-offset} - #{get-side($input-padding, right)});
        margin-bottom: -#{$label-line-height / 2};
        padding: 0 $label-padding;
    }
    &.required legend::after {
        content: "*";
        display:inline;
        vertical-align: middle;
        line-height: 0;
        margin-left: .4em;
        color: $required-color;
    }
    & > .input-error {
        $icon-size:$label-font-size;
        @include form-message-size(
            $line-height: $label-line-height,
            $icon-size: $icon-size,
            $icon-margin: $label-padding
        );
        margin-left: auto;
        margin-right: calc(#{$label-x-offset} - #{get-side($input-padding, right)});
        margin-top: -#{$label-line-height / 2};
        margin-bottom: -#{$label-line-height / 2};
        transform: translateY(#{get-side($input-padding, bottom)});
        max-width: calc(100% - #{$label-x-offset * 2});
        width: -moz-fit-content;
        width: fit-content;
        padding-left: calc(#{$label-padding * 2} + #{$icon-size});
        padding-right: $label-padding;
        border-radius: $input-border-radius;
        overflow: hidden;
        &::after {
            left: $label-padding;
        }
    }
}

@function get-input-label-top-props($props: ()) {
    @return map-merge((
        label-margin: $baseline / 2
    ), $props);
}

@mixin input-label-top(
    $props: ()
) {
    $props: get-input-props($props, input-label-top);
    $label-selector: map-get($props, label-selector);
    $label-margin: map-get($props, label-margin);
    display: flex;
    flex-direction: column;
    #{$label-selector} {
        order: 0;
    }
    .input-icon {
        order: 1;
    }
    #{text-inputs()},
    select,
    textarea,
    .cke {
        order: 2;
        margin-top: $label-margin;
    }
    .input-error {
        order: 3;
    }
    .button {
        align-self: flex-start;
    }
}
