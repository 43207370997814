@import 'parent:';

@mixin button {
    $button-colors: get-button-colors('base');
    @include button-color('base');
    font-weight: $medium;
    overflow: hidden;
    position: relative;
    box-shadow: 0 (2/$em) * 1em 0 $anchor-color-hover;
    @include breakpoint(xlarge up) {
        font-size: (20/$em) * 1rem;
    }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        padding-bottom: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.2);
        transform-origin: center;
        transition: transform .3s ease,
                    opacity .3s ease;
        border-radius: 50%;
        opacity: 0;
        pointer-events: none;
        background-color: rgba($black, 0.1);    
    }
    &:hover,
    &:focus {
        background-color: map-get($button-colors, background);
        border-color: map-get($button-colors, border);
        &::after {
            transform: translate(-50%, -50%) scale(1.1);
            opacity: 1;
        }
    }
    .icon + span,
    span + .icon {
        margin-left: .5em;
    }
}
@mixin button-reset {
    box-shadow: none;
    overflow: visible;
    position: static;
    &::after {
        position: static;
        width: auto;
        padding: 0;
        left: auto;
        top: auto;
        transform: none;
        transition: none;
        border-radius: 0;
        opacity: 1;
        pointer-events: auto;
        background-color: transparent;
    }
}
@mixin button-text-only {
    @include button-color('text-only');
    font-weight: $medium;
    padding: 0;
    border: 0;
    border-radius: 0;
    min-width: 0;
    text-align: left;
    &::after {
        content: '';
        display: inline-block;
        width: .6em;
        height: .6em;
        margin-left: .4em;
        margin-bottom: .05em;
        border-right: (2/$em) * 1em solid currentColor;
        border-top: (2/$em) * 1em solid currentColor;
        transform: rotate(45deg);
        transition: transform .2s;
    }
    &:hover,
    &:focus {
        &::after {
            transform: translateX(25%) rotate(45deg);
        }
    }
}
