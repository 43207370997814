@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'icons';
$baseline: .75rem !default;

$article-icons-props: () !default;
$article-props: () !default;

@function get-article-icons-props($props: ()) {
    //defaults -> article-icons-props -> local props -> cascaded defaults -> local props
    $defaults: map-merge((
        color: $text-color-regular,
        hover-color: $anchor-color-hover,
        size: $baseline * 2,
    ), $article-icons-props);

    $base-props: map-merge($defaults, $props);

    $props: map-merge((
        background-size: map-get($base-props, size),
    ), $base-props);

    @return $props;
}

@function get-article-props($props: ()) {
    //defaults -> article-props -> local props -> cascaded defaults -> local props
    $defaults: map-merge((
        image-size: (
            small: $baseline * 2.5,
            smallplus: $baseline * 4,
            medium: $baseline * 6
        ),
        banner-size: (
            small: #{120/$em}rem,
            smallplus: #{240/$em}rem,
        ),
        baseline: $baseline,
        gutter: (
            small: $baseline,
            medium: $baseline * 2,
        ),
        content-width: none,
        compact-breakpoint: false,
        title-typography: header-c,
        summary-typography: text-large,
    ), $article-props);

    $base-props: map-merge($defaults, $props);

    $props: map-merge((
        content-baseline: (
            small: map-get($base-props, baseline),
            medium: map-get($base-props, baseline) * 1.5
        ),
        head-padding: map-get($base-props, baseline) * 1.5
    ), $base-props);

    @return $props;
}

@mixin article-icons(
    $color: null,
    $hover-color: null,
    $size: null,
    $background-size: null,
    $props: ()
) {
    @if $color {
        $props: map-merge($props, (color: $color));
    }
    @if $hover-color {
        $props: map-merge($props, (hover-color: $hover-color));
    }
    @if $size {
        $props: map-merge($props, (size: $size));
    }
    @if $background-size {
        $props: map-merge($props, (background-size: $background-size));
    }

    $props: get-article-icons-props($props);

    $color: map-get($props, color);
    $hover-color: map-get($props, hover-color);
    $size: map-get($props, size);
    $background-size: map-get($props, background-size);

    .head::after {
        background-position: center;
        background-repeat: no-repeat;
        @include breakout($size) {
            width: $value;
            height: $value;
        }
        @include breakout($background-size) {
            background-size: $value $value;
        }
    }
    .item:not(.none) .head::after {
        content: '';
        display: block;
    }
    .expander-head {
        .head::after {
            @include icon-plus($color);
        }
        &:hover:not(.is-expanded),
        &:focus:not(.is-expanded),
        &.is-active:not(.is-expanded)  {
            .head::after {
                @include icon-plus($hover-color);
            }
        }
        &.is-expanded .head::after {
            @include icon-minus($color);
        }
        &.is-expanded:hover .head::after,
        &.is-expanded:focus .head::after {
            @include icon-minus($hover-color);
        }
    }
    .item.page,
    .item.link,
    .item.issue {
        .head::after {
            @include icon-arrow($color);
        }
        a:hover,
        a:focus,
        a.is-active {
            .head::after {
                @include icon-arrow($hover-color);
            }
        }
    }
    .item.download {
        .head::after {
            @include icon-download($color);
        }
        a:hover,
        a:focus,
        a.is-active  {
            .head::after {
                @include icon-download($hover-color);
            }
        }
    }
}
@mixin basic-article-list(
    $image-size: null,
    $banner-size: null,
    $baseline: null,
    $gutter: null,
    $content-width: null,
    $compact-breakpoint: null,
    $props: ()
) {
    @if $image-size {
        $props: map-merge($props, (image-size: $image-size));
    }
    @if $banner-size {
        $props: map-merge($props, (banner-size: $banner-size));
    }
    @if $baseline {
        $props: map-merge($props, (baseline: $baseline));
    }
    @if $gutter {
        $props: map-merge($props, (gutter: $gutter));
    }
    @if $content-width {
        $props: map-merge($props, (content-width: $content-width));
    }
    @if $compact-breakpoint {
        $props: map-merge($props, (compact-breakpoint: $compact-breakpoint));
    }
    $props: get-article-props($props);
    $image-size: map-get($props, image-size);
    $banner-size: map-get($props, banner-size);
    $baseline: map-get($props, baseline);
    $head-padding: map-get($props, head-padding);
    $gutter: map-get($props, gutter);
    $content-width: map-get($props, content-width);
    $compact-breakpoint: map-get($props, compact-breakpoint);
    $title-typography: map-get($props, title-typography);
    $summary-typography: map-get($props, summary-typography);

    @include article-expander($props: $props);

    a.head-link {
        color: inherit;
        text-decoration: inherit;
        outline: none;
    }
    .head-link {
        cursor: pointer;
    }
    .item,
    .head-link {
        display: block;
    }
    .image {
        @include object-fit;
        display: flex;
        img,
        svg {
            width: 100%;
        }
    }
    .head {
        display: flex;
        @include breakout($head-padding) {
            padding-top: $value;
            padding-bottom: $value;
        }
        .image {
            flex-shrink: 0;
            @include breakout($image-size) {
                width: $value;
                height: $value;
            }
            svg {
                width: 100%;
            }
        }
        .text-group {
            flex-basis: 50%; //ie11
            flex-grow: 1;
            & > * {
                margin-top: $baseline / 2;
            }
            & > *:empty {
                display: none;
            }
            & > *:first-child,
            .date-posted {
                margin-top: 0;   
            }
        }
        &::after {
            flex-shrink: 0;
            margin-left: auto;
        }
        .title {
            @include typography($title-typography);
        }
        .summary {
            @include typography($summary-typography);
        }
    }
    .head .text-group,
    .expander-bodyu > * {
        max-width: $content-width;
        flex-basis: $content-width;
        padding-left: 0;
        @include breakout($gutter) {
            padding-right: $value;
        }
    }
    .image + .text-group {
        @include breakout($gutter) {
            padding-left: $value;
        }
    }
    .has-image .expander-bodyu {
        & > * {
            @include breakout($gutter) {
                margin-left: $value;
            }
        }
        @include breakout($image-size) {
            margin-left: calc(#{$value});
        }
    }           
    @if $compact-breakpoint != false {
    @supports ((display: contents) and (display: grid)) {
        @include breakpoint($compact-breakpoint down) {
            .has-image .head {
                $smallGutter: -zf-get-bp-val($gutter, $compact-breakpoint);
                display: grid;
                grid-template-columns: auto 1fr auto;
                grid-column-gap: $smallGutter;
                align-items: start;
                .text-group {
                    display: contents;
                }
                .image {
                    grid-column: 1;
                    grid-row: 1;
                }
                .title {
                    grid-row: 1;
                    grid-column: 2;
                    margin: 0;
                    align-self: center;
                }
                .summary,
                .date-posted {
                    grid-column: 1 / span 3;
                    margin: 0;
                }
                .summary {
                    grid-row: 2;
                    margin-top: $baseline;
                }
                .date-posted {
                    grid-row: 3;
                    grid-column: 1 / span 3;
                    margin-top: $baseline / 2;
                    font-size: .8em;
                }
                &::after {
                    grid-row: 1;
                    grid-column: 3;
                }
            }
            .has-image .expander-bodyu {
                margin-left: 0;
                & > * {
                    margin-left: 0;
                }
            }
        }       
    }
    }
    .item.is-active {
        display: none;
    }
}

@mixin article-expander(
    $body-selector: '.expander-bodyu',
    $props: ()
) {
    $props: get-article-props($props);

    #{$body-selector} {
        @include breakout(map-get($props, head-padding)) {
            padding-bottom: $value;
        }
        @include breakout(map-get($props, content-baseline)) {
            & > * {
                margin-top: $value;
            }
        }
        & > *:first-child {
            margin-top: 0;
        }
        & > .image {
            @include object-fit;
            display: flex;
            @include breakout(map-get($props, banner-size)) {
                height: $value;
            }
        } 
    }
    #{$body-selector}:focus {
        outline: none;
    }
    .video-cont {
        display: block;
        position: relative;
        padding-bottom: percentage(9/16);
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

}

@mixin article-expander-enhance($props: (), $selector: '.expander') {
    $props: get-article-props($props);
    
    $head-padding: map-get($props, head-padding);
    $baseline: map-get($props, baseline);
    $compact-breakpoint: map-get($props, compact-breakpoint);

    $compact-breakpoint-up: small;
    @if $compact-breakpoint {
        $compact-breakpoint-up: breakpoint-after($compact-breakpoint);
    }

    #{$selector} {
        .expander-bodyu > * {
            opacity: 0;
            transition: opacity .2s;
        }
        .is-expanded ~ .expander-body-cont .expander-bodyu:not(.sliding) > * {
            opacity: 1;
        }
        @supports (display: contents) {
            @include breakpoint($compact-breakpoint-up up) {
                display: grid;
                grid-template-columns: auto 1fr auto;
                grid-template-rows: 1fr min-content;
                @include breakout($head-padding) {
                    padding-top: $value - $baseline;
                    padding-bottom: $value - $baseline;
                }
                .expander-head,
                .head-link,
                .head,
                .expander-body-cont {
                    display: contents;
                }
                .head {
                    .image,
                    .text-group,
                    &::after {
                        margin-top: $baseline;
                        margin-bottom: $baseline;
                    }
                    .text-group,
                    &::after {
                        grid-row: 1;
                        align-self: center;
                    }
                    &::before {
                        content: '';
                        display: block;
                        grid-column: 1 / span 3;
                        grid-row: 1;
                    }
                    .image {
                        grid-row: 1 / span 2;
                        grid-column: 1;
                    }
                    .text-group {
                        grid-column: 2;
                    }
                    &::after {
                        grid-column: 3;
                    }
                }
                .expander-bodyu {
                    grid-row: 2;
                    grid-column: 2 / span 2;
                    margin-left: 0;
                    padding-bottom: $baseline;
                }
            }
        }
    }
}

