.footer {
    @include grid;
    @include grid14;
    color: $text-color-white;
    background-color: $footer-background-color;
    box-shadow: 0 10rem $footer-background-color;
    & > * {
        @include grid-column(1, 12, true);
        @include breakout($content-gutters) {
            padding-left: $value;
            padding-right: $value;
        }
        @include breakout($section-padding) {
            margin-top: $value * 2/3;
            margin-bottom: $value * 2/3;
        }
    }
    a {
        color: $text-color-white;
        &:hover,
        &:focus {
            color: $anchor-color-white-hover;
        }
    }
    .title{
        @include typography(header-b);
    }
    .logo {
        img {
            filter: grayscale(1) brightness(20);
            height: 1.5em;
        }
        
    }
    // .contact-form .title {
    //     text-align: center;
    // }
    .footer-contact-details {
        @include grid-row(1);
        @include grid-justify-self(center);
        max-width: 18em;
        width: 100%;
    }
    .footer-crisis {
        @include grid-row(2);
        padding-left: 0;
        padding-right: 0;
    }
    // .contact_form {
    //     @include grid-row(2);
    // }
    // .footer-privacy {
    //     @include grid-row(2);
    //     @include grid-justify-self(end);
    //     @include grid-align-self(end);
    //     position: relative;
    //     z-index: 2;
    //     margin-top: 0;
    //     pointer-events: none;
    //     a {
    //         $button-padding: map-get(get-input-props((), button-base), button-padding);
    //         display: inline-block;
    //         padding-top: get-side($button-padding, top);
    //         padding-bottom: get-side($button-padding, top);
    //         pointer-events: auto;
    //     }
    // }
    .footer-acknowledgement {
        @include grid-row(3);
        @include typography(text-small);
        @include grid-justify-self(center);
        margin-top: $baseline * 2;
        color: $text-color-white;
        max-width: 28em;
        text-align: center;
    }
    .footer-copyright {
        @include grid-row(4);
        margin-top: 0;
        text-align: center;
    }
    &::before {
        @include grid-row(1);
        @include grid-column(1, 14);
        @include grid-justify-self(stretch);
        @include grid-align-self(stretch);
        content: '';
        display: block;
        background-color: $footer-contact-background-color;
    }
    @media not print {

        @include breakpoint(medium up) {
            @include grid-template-rows(1fr auto auto);
            .footer-contact-details,
            .footer-acknowledgement {
                @include grid-column(1, 5, true);
                @include grid-justify-self(start);
            }
            // .contact-form .title {
            //     text-align: left;
            // }
            // .contact_form,
            // .footer-privacy {
            //     @include grid-row(1, 2);
            //     @include grid-column(7, 6, true);
            // }
            .footer-acknowledgement {
                @include grid-row(2);
                padding-right: $baseline * 4;
                text-align: left;
            }
            .footer-crisis {
                @include grid-row(1, 3);
                @include grid-column(7, 6, true);
            }
            .footer-copyright {
                @include grid-row(3);
                @include grid-column(1, 5, true);
                @include grid-justify-self(start);
                @include grid-align-self(end);
                text-align: left;
                max-width: 18em;
                width: 100%;
            }
            &::before {
                @include grid-row(1, 2);
                @include grid-column(1, 6);
                box-shadow: 0 10rem $footer-contact-background-color;
            }
        }
        @include breakpoint(large up) {
            .footer-contact-details,
            .footer-acknowledgement {
                @include grid-column(1, 4, true);
            }
            .footer-crisis {
                @include grid-column(6, 7, true);
            }
            &::before {
                @include grid-column(1, 5);
            }
        }
        @include breakpoint(xlarge up) {
            .footer-contact-details,
            .footer-acknowledgement,
            .footer-copyright {
                @include grid-justify-self(start);
                @include grid-column(2, 3, true);
            }
            // .contact_form,
            // .footer-privacy {
            //     @include grid-column(7, 5, true);
            // }
        }
        @include breakpoint(xxlarge up) {
            .footer-contact-details,
            .footer-acknowledgement,
            .footer-copyright {
                @include grid-column(2, 3, true);
            }
            // .contact_form,
            // .footer-privacy {
            //     @include grid-column(7, 4, true);
            // }
        }
    }
    @media print {
        .footer-crisis,
        .footer-acknowledgement,
        .footer-copyright,
        .contact-social {
            display: none;
        }
        .footer-contact-details {
            max-width: none;
        }
    }
}

.footer-contact-details {
    @include grid;
    @include grid-template-columns(1fr auto);
    @include grid-template-rows(auto 1fr);
    font-size: (18/$em) * 1rem;
    line-height: $baseline * 2;
    @include breakpoint(xlarge up) {
        font-size: (20/$em) * 1rem;
    }
    .title {
        @include grid-row(1);
        @include grid-column(1);
        .icon {
            font-size: (24/$em) * 1rem;
            & + span {
                margin-left: (6/$em) * 1rem;
            }
        }
    }
    .contact-list {
        @include grid-row(2);
        @include grid-column(1);
        // margin-top: $baseline;
        li {
            display: flex;
            margin-top: $baseline * 1.5;
        }
        .icon {
            font-size: (16/$em) * 1rem;
            margin-left: (4/$em) * 1rem;
            & + span {
                margin-left: (10/$em) * 1rem;
            }
        }
    }
    .contact-social {
        @include grid-row(1, 2);
        @include grid-column(2);
        // margin-top: $baseline * 1.5;
        font-size: (24/$em) * 1rem;
        li {
            display: flex;
            // margin-top: $baseline / 2;
        }
        a {
            display: flex;
            padding: .375em;
        }
    }
    @include breakpoint(medium up) {
        display: block;
        .contact-social {
            margin-top: $baseline * 1.5;
            li {
                display: inline-flex;
                margin-right: $baseline / 2;
            }
            a {
                padding: (4/$em) * 1rem;
            }
        }
    }
    @include breakpoint(xlarge up) {
        .title {
            .icon {
                font-size: (28/$em) * 1rem;
                & + span {
                    margin-left: (12/$em) * 1rem;
                }
            }
        }
        .contact-list {
            margin-top: $baseline * 2;
            .icon {
                font-size: (18/$em) * 1rem;
                margin-left: (5/$em) * 1rem;
                & + span {
                    margin-left: (17/$em) * 1rem;
                }
            }
        }
        .contact-social {
            margin-top: $baseline * 2.5;
            a {
                padding: (5/$em) * 1rem;
            }
        }
    }
    @media print {
        .contact-list {
            display: flex;
            margin-top: 0;
            li {
                margin-right: 1.25em;
            }
        }
    }
}

.footer-crisis {
    text-align: center;
    @include breakout($content-gutters) {
        .crisis-text,
        .crisis-contact {
            padding-left: $value;
            padding-right: $value;
        }
    }
    .crisis-text {
        max-width: 58ch;
        margin: 0 auto;
    }
    .crisis-contact-list {
        flex-wrap: wrap;
    }
    .crisis-contact {
        margin-top: $baseline * 2;
        flex-basis: 50%;
    }
    .crisis-contact-number {
        margin-top: $baseline / 2;
        display: block;
    }
    @include breakpoint(medium up) {
        text-align: left;
        .crisis-text {
            margin-left: 0;
            margin-right: 0;
        }
    }
    @include breakpoint(large up) {
        .crisis-contact-list {
            display: flex;
        }
    }
}