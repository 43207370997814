@import 'input-label-absolute';

@function get-input-material-props($props: ()) {
    $defaults: map-merge((
        label-font-size: (14/$em) * 1rem,
        label-line-height: (16/$em),
        label-background-bleed: .375rem,
        error-margin: (2/$em) * 1rem,
    ), $props);
    @return map-merge((
        error-font-size: map-get($defaults, label-font-size),
        error-line-height: map-get($defaults, label-line-height),
    ), $defaults);
}

@mixin input-material-colors($props: ()) {
    $props: get-input-props($props, input-material input-colors);
    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);

    $border-width: get-border-value(map-get($props, input-border), width);

    #{$label-selector} {
        background-color: map-get($props, input-background-color);
    }

    #{$field-selector} {
        border-color: map-get($props, input-background-color);
        box-shadow: 0 $border-width 0 map-get($props, input-border-color);
        margin-bottom: $border-width;
        transition: box-shadow .3s ease,
        color .3s ease,
        opacity .3s ease;
        &:focus {
            border-color: map-get($props, input-background-color);
            box-shadow: 0 $border-width 0 map-get($props, input-border-focus-color);
        }
    }
    &.has-error-message #{$field-selector} {
        border-color: map-get($props, input-background-color);
        box-shadow: 0 $border-width 0 map-get($props, input-border-alert-color);
        &:focus {
            border-color: map-get($props, input-background-color);
            box-shadow: 0 $border-width 0 map-get($props, input-border-focus-color);
        }
    }
}
@mixin input-material-unset($props) {
    $props: get-input-props($props, input-colors);
    $field-selector: map-get($props, field-selector);
    $label-selector: map-get($props, label-selector);

    $border-width: get-border-value(map-get($props, input-border), width);

    display: block;
    flex-direction: row;

    #{$label-selector} {
        @include input-label-absolute-floating-text-unset;
        position: static;
        background-color: transparent;
        font-size: map-get($props, label-font-size);
        line-height: map-get($props, label-line-height);
    }
    #{$field-selector} {
        border-color: map-get($props, input-border-color);
        box-shadow: none;
        &:focus {
            box-shadow: none;
            border-color: map-get($props, input-border-focus-color);
        }
    }
    &.has-error-message #{$field-selector} {
        border-color: map-get($props, input-border-alert-color);
        box-shadow: none;
        &:focus {
            border-color: map-get($props, input-border-focus-color);
            box-shadow: none;
        }
    }
    
    .input-error {
        @include form-message-size;
        font-size: initial;
        margin-top: 0;
    }

    #{field-selector} {
        border-color: map-get($props, input-border-color);
        &:focus {
            border-color: map-get($props, input-border-focus-color);
        }
    }
    &.has-error-message #{$field-selector} {
        border-color: map-get($props, input-border-alert-color);
        &:focus {
            border-color: map-get($props, input-border-focus-color);
        }
    }
}
@mixin input-material($props: ()) {
    $props: get-input-props($props, input-material);
    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);

    $input-border-radius: map-get($props, input-border-radius); 
    
    $label-x-offset: 0;
    $label-padding: get-side(map-get($props, input-padding), left);
    $label-background-bleed: map-get($props, label-background-bleed);
    $label-font-size: map-get($props, label-font-size);
    $label-line-height: get-input-line-height($props, $type: label);
    
    $error-margin: map-get($props, error-margin);
    $error-font-size: map-get($props, error-font-size);
    $error-line-height: get-input-line-height($props, $type: error);

    position: relative;
    display: flex;
    flex-direction: column;
    
    .button {
        align-self: center;
    }
    
    #{$field-selector} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // margin-bottom: calc(#{$error-line-height} + #{$error-margin});
    }
    
    #{$label-selector} {
        @include input-label-absolute-floating-text(
            $label-font-size,
            $label-line-height,
            $label-x-offset,
            $label-padding,
            $input-border-radius
        );
        z-index: 4;
        order: -1;
        margin-top: 0;
        padding-top: $label-background-bleed;
    }

    .input-error {
        @include form-message-size(
            $line-height: $error-line-height,
            $icon-size: $error-font-size
        );
        margin-top: $error-margin;
        font-size: $error-font-size;
        align-self: flex-end;
    }
}
@function get-input-material-fieldset-props($props: ()) {
    $defaults: get-input-props($props, input-material);

    $props: map-merge((
        label-x-offset: 0px,
        label-padding: get-side(map-get($defaults, input-padding), left)
    ), $defaults);
    @return get-input-props($props, input-fancy-fieldset);
}
@mixin input-material-fieldset-colors($props: ()) {
    $props: get-input-props($props, input-material input-colors);
    $border-width: get-border-value(map-get($props, input-border), width);

    background-color: map-get($props, input-background-color);
    border-color: map-get($props, input-background-color);
    box-shadow: 0 $border-width 0 map-get($props, input-border-color);
    transition: box-shadow .3s ease,
    color .3s ease,
    opacity .3s ease;
    &.has-error-message {
        border-color: map-get($props, input-background-color);
        box-shadow: 0 $border-width 0 map-get($props, input-border-alert-color);
    }
    &:focus-within {
        border-color: map-get($props, input-background-color);
        box-shadow: 0 $border-width 0 map-get($props, input-border-focus-color);
    }
    legend {
        color: map-get($props, legend-text-color);
        background-color: map-get($props, input-background-color);
    }
}
@mixin input-material-fieldset($props: ()) {
    $props: get-input-props($props, input-material-fieldset);
    @include apply-input-mixin(input-fancy-fieldset, $props);

    $vertical-margin: map-get($props, vertical-margin);
    
    $input-padding: map-get($props, input-padding);
    $input-border-width: get-border-value(map-get($props, input-border), width);
    $label-background-bleed: map-get($props, label-background-bleed);

    $error-margin: map-get($props, error-margin);
    $error-font-size: map-get($props, error-font-size);
    $error-line-height: map-get($props, error-line-height);
    @if unit($error-line-height) == '' {
        $error-line-height: $error-font-size * $error-line-height;
    }
    margin-top: $vertical-margin;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    // line-height: 0;

    legend {
        width: calc(100% + #{get-side($input-padding, left)} + #{get-side($input-padding, left)});
        padding-top: $label-background-bleed;
    }
    & > .input-error {
        @include form-message-size(
            $line-height: $error-line-height,
            $icon-size: $error-font-size
        );
        transform: translateY(calc(#{$input-border-width} - #{$error-margin}));
        font-size: $error-font-size;
        &::after {
            left: 0;
        }
    }
}
@mixin input-material-keyframes {
    @keyframes input-material-error-in {
        from {
            opacity: 0;
            line-height: 0;
            margin: 0;
            min-height: 0;
        }
    }
}