@import 'site:variables';

@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/typography';

@import 'mixins';

.lister.article-list-team {
    @include team-list;
}
