@import 'site:variables';

@import '@depot/breakpoint';
@import '@depot/grid';

@import 'site:page-mixins';
@import 'forms:mixins';

@import 'mixins';

.layout-login {
    .content {
        display: flex;
        background-color: $off-white;
    }
    .page-layout {
        // @include breakout($header-height) {
        //     margin-top: $value;
        // }
        @include breakout($outer-gutters) {
            padding: $value;
        }
        margin-top: $baseline * 6;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
}

.sb-login {
    @include login-form;
    @include login-form-colors(
        $header-background-color: $primary-color
    );
    .login-button {
        @include apply-input-mixin(button-base);
        @include button;
    }
    .login-brand-logo img {
        filter: grayscale(1) brightness(10);
    }
}

.layout-therapy-login {
    .content {
        background-color: $off-white;
    }
    .page-layout {
        @include page-layout-full($minimal: true);
        @include breakout($section-padding) {
            margin-top: $value;
            margin-bottom: $value;
        }
        & > * {
            margin-top: 0;
            margin-bottom: 0;
        }
        .group.page {
            margin-left: 0;
            margin-right: 0;
        }
        .form-paper {
            margin-top: $baseline * 2;
            margin-bottom: 0;
        }
        .form-paper.join {
            @include grid-row(3);
        }
        .form-paper.login {
            @include grid-row(2);
        }
        @include breakpoint(medium) {
            .form-paper {
                @include grid-align-self(start);
            }
            .form-paper.join {
                @include grid-row(2);
                @include grid-column(1, 6, true);
                max-width: (700rem / 16),
            }
            .form-paper.login {
                @include grid-row(2);
                @include grid-column(7, 6, true);
                max-width: (420rem / 16);
                background-color: transparent;
                box-shadow: none;
            }
        }
        @include breakpoint(large up) {
            .form-paper.join {
                @include grid-column(1, 6, true);
            }
            .form-paper.login {
                @include grid-column(7, 4, true);
            }
        }
        @include breakpoint(xlarge up) {
            .item-group.page {
                @include grid-column(2, 10, true);
            }
            .form-paper.join {
                @include grid-column(2, 5, true);
            }
        }
    }
    .form-paper {
        .login-button {
            @include apply-input-mixin(button-base);
            @include button;
            margin-right: 1em;
        }
        .password-link {
            display: inline-block;
            margin: $baseline 0;
            line-height: 1;
        }
        form {
            $vertical-margin: map-get(get-input-props(), vertical-margin);
            margin-top: 0;
            margin-left: $vertical-margin * -1;
            margin-right: $vertical-margin * -1;
            & > * {
                margin-top: $vertical-margin;
                margin-left: $vertical-margin / 2;
                margin-right: $vertical-margin / 2;
            }
        }
    }
    .form-paper.join form {
        display: flex;
        flex-wrap: wrap;
        & > .input {
            flex-basis: 15rem;
            flex-grow: 1;
        }
        & > .message,
        .button-cont {
            flex-basis: calc(100% - #{$gutter * 2});
        }
    }
}
