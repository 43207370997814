@mixin callouts-alternating(
    $columns: 6 6,
    $flip-columns: 6 6,
    $alternate-rule: even,
    $item-grid-selector: '&:not(.media)',
    $image-selector: '.head-image',
    $item-margin: $baseline * 3,
    $item-baseline: $baseline * 2,
    $item-inner-baseline: $baseline * 2,
    $item-gutter: $gutter,
    $image-height: (
        small: $baseline * 20,
        medium: $baseline * 35
    ),
    $content-width: $content-width,
    $shrink-wrap: true,
    // supports multiple breakpoints, ex: (medium only, 1200px up)
    $grid-breakpoints: medium up,
    $content-grid-override: false
) {
    .item {
        @include callouts-alternating-item(
            $image-height: $image-height,
            $item-baseline: $item-baseline,
            $item-inner-baseline: $item-inner-baseline,
            $content-width: $content-width,
            $image-selector: $image-selector
        );
        @include breakout($columns) {
            @include callouts-alternating-item-content-columns(
                $columns: $value,
                $first-selector: $image-selector,
                $second-selector: '.text-group',
                $columns-override: $content-grid-override
            );
        }
        #{$item-grid-selector} {
            @if list-separator($grid-breakpoints) != space {
                @each $item in $grid-breakpoints {
                    @include breakpoint($item) {
                        @include callouts-alternating-item-grid(
                            $image-selector: $image-selector, 
                            $gutter: $item-gutter,
                            $shrink-wrap: $shrink-wrap
                        );
                    }
                }
            } @else {
                @include breakpoint($grid-breakpoints) {
                    @include callouts-alternating-item-grid(
                        $image-selector: $image-selector,
                        $gutter: $item-gutter,
                        $shrink-wrap: $shrink-wrap
                    );
                }
            }
        }
        position: relative;
        @include breakout($item-gutter) {
            .text-group {
                padding-left: $value;
                padding-right: $value;
            }
        }
    }
    @if $alternate-rule {
        .item:nth-child(#{$alternate-rule}) {
            // @include callouts-alternating-item-flip($flip-columns, $image-selector: $image-selector);
            @include breakout($flip-columns) {
                @include callouts-alternating-item-content-columns(
                    $columns: $value,
                    $first-selector: '.text-group',
                    $second-selector: $image-selector,
                    $columns-override: $content-grid-override
                );
            }
        }
    }
    .item.item {
        @include breakout($content-grid-override) {
            @if $value == 14 {
                @include grid14;
            } @else {
                @include subgrid($value);
            }
        }
    }
    .item:not(:first-child) {
        @include breakout($item-margin) {
            margin-top: $value;
        }
    }
    .item .item {
        margin-top: 0;
    }
}

@mixin callouts-alternating-item-grid(
    $image-selector: '.head-image',
    $gutter: $gutter,
    $shrink-wrap: true
) {
    @include grid;
    @include breakout($gutter) {
        @if $shrink-wrap {
            margin-left: $value * -1;
            margin-right: $value * -1;
        }
        & > *,
        &::before {
            padding-left: $value;
            padding-right: $value;
        }
    }
    .text-group {
        margin-top: 0;
        &:not(:first-child) {
            max-width: none;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

@mixin callouts-alternating-item(
    $image-height: (
        small: $baseline * 22,
        medium: $baseline * 45
    ),
    $item-baseline: $baseline * 2,
    $item-inner-baseline: $baseline * 2,
    $content-width: $content-width,
    $image-selector: '.head-image'
) {
    display: flex;
    flex-direction: column;
    @include breakout($item-baseline) {
        @include grid-template-rows($value auto $value);
    }
    #{$image-selector} {
        @include grid-row(1, 3);
        display: flex;
        flex-direction: column;
    }
    .video iframe {
        width: 100% !important;
        height: 100% !important;
    }
    .image {
        @include object-fit;
        display: flex;
        flex-grow: 1;
        svg {
            width: 100%;
        }
    }
    .text-group {
        @include grid-row(1, 3);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        @include breakout($content-width) {
            max-width: $value;
        }
        & > * {
            @include breakout($item-inner-baseline) {
                margin-top: $value;
            }
            max-width: 100%;
            &:first-child {
                margin-top: 0;
            }
        }
        & > .title {
            margin-top: 0;
        }
        & > .date-posted {
            margin-top: 0;
        }
    }
    #{$image-selector} ~ .text-group {
        @include grid-row(2);
        @include breakout($item-inner-baseline) {
            margin-top: $value * 1.5;
        }
    }
    .title {
        @include typography(header-b);
    }
    .head-link {
        display: block;
        // color: inherit;
    }
    @include breakout($image-height) {
        .image {
            height: $value;
        }
    }
}

@mixin callouts-alternating-item-content-columns(
    $columns,
    $first-selector,
    $second-selector,
    $columns-override: false
) {
    $first-area: nth($columns, 1);
    $second-area: nth($columns, 2);

    $first-start: 1;
    $first-span: $first-area;
    @if type-of($first-area) != number {
        $first-start: nth($first-area, 1);
        $first-span: nth($first-area, 2);
    }

    $second-start: $first-start + $first-span;
    $second-span: $second-area;
    @if type-of($second-area) != number {
        $second-start: nth($second-area, 1);
        $second-span: nth($second-area, 2);
    }

    //not necessarily accurate guess at total columns
    $first-columns: $first-start + $first-span - 1;
    $second-columns: ($second-start - $first-columns - 1) + $second-span;
    $column-count: $first-columns + $second-columns;
    @if length($columns) == 3 {
        $column-count: nth($columns, 3);
    }

    @if not $columns-override {
        @include subgrid($column-count);
    }

    #{$first-selector} {
        @include grid-column($first-start, $first-span);
        &:last-child:first-child {
            @include grid-column($first-start, $first-span + $second-span);
        }
    }
    #{$second-selector} {
        @include grid-column($second-start, $second-span);
        &:last-child:first-child {
            @include grid-column($first-start, $first-span + $second-span);
        }
    }
}