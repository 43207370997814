$baseline: .75rem !default;

$mobile-breakpoint-up: medium !default;
$sticky-header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: 0,
) !default;
$header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: $baseline * 8,
) !default;
$header-navigation-min-height: 400px !default;
$header-navigation-reserved-height: 72px !default;
$header-height-breakpoint: $header-navigation-min-height + $header-navigation-reserved-height;

@mixin sticky-header-base(
    $header-inner-selector: '.header-inner'
) {
    html {
        @include breakout($sticky-header-height) {
            @if $value != 0 {
                scroll-padding-top: $value;
            }
        }
    }
    .header {
        z-index: 29;
        position: relative;
        top: 0;
        @include breakout($header-height) {
            min-height: $value;
        }
        @supports ((position: -webkit-sticky) or (position: sticky)) {
            @media screen and (min-height: $header-height-breakpoint) {  
                @include breakout($sticky-header-height) {
                    @if $value != 0 {
                        position: -webkit-sticky;
                        position: sticky;
                        top: (get-breakpoint-value($header-height, $breakpoint) - $value) * -1;
                    } @else {
                        position: relative;
                        top: 0;
                    }
                }
            }
        }
    }
    #{$header-inner-selector} {
        min-height: 0;
        @include breakout($header-height) {
            height: $value;
        }
    }
}

@mixin header-flyin(
    $sizes: (
        $mobile-breakpoint-down: 50%,
        smallplus: 100%
    ),
    $header-inner-selector: '.header-inner',
    $flyin-selector: '.primary-navigation',
    $underlay-color: rgba($dark-gray, 0.5),
    $menu-icon-background-color: $off-white,
    $menu-button-size: 48px,
    $menu-button-x-offset: 0
) {
    #{$header-inner-selector} .mobile-menu-expand {
        z-index: 2;
        position: absolute;
        // top: 0;
        transition: right .4s ease;
        @include breakout($menu-button-x-offset) {
            right: $value;
        }
        &::before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 100vh;
            pointer-events: none;
            opacity: 0;
            background-color: $underlay-color;
            transition: opacity .4s ease;
        }
        &::after {
            content: '';
            z-index: 1;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $menu-icon-background-color;
            opacity: 0;
            transition: opacity .4s;
        }
        & > * {
            z-index: 2;
        }
    }
    @include breakpoint(nth(map-keys($sizes), 1) down) {    
        html {
            overflow-x: hidden;
        }
        #{$header-inner-selector} {
            position: relative;
        }
        #{$flyin-selector} {
            z-index: 3;
            position: fixed;
            top: 0;
            // bottom: 0;
            left: 100%;
            height: 100vh;
            margin: 0;
            margin-left: $menu-button-size;
            transition: transform .4s ease;
        }
        .open {
            #{$flyin-selector} {
                transform: translateX(calc(-100% - #{$menu-button-size}));
            }
            .mobile-menu-expand {
                &::before {
                    pointer-events: auto;
                    opacity: 1;
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    @each $breakpoint, $size in $sizes {
        @include breakpoint($breakpoint down) {
            #{$flyin-selector} {
                width: calc(#{$size} - #{$menu-button-size});
            }
            .open {
                #{$flyin-selector} {
                    // left: calc(100% - #{$size});
                }
                .mobile-menu-expand {
                    right: calc(#{$size} - #{$menu-button-size});
                }
            }
        }
    }
}

@mixin header-collapse(
    $header-transition: all .4s ease,
    $header-transition-open: $header-transition,
    $header-item-transition: 'opacity .2s, visibility 0s .2s',
    $header-item-transition-open: opacity .2s .3s,
    $collapse-selectors: '.search-cont, .primary-navigation, .nav-social, .nav-extra',
    $header-inner-selector: '.header-inner'
) {
    #{$header-inner-selector} {
        @media screen and (min-height: $header-height-breakpoint) {
            position: absolute;
            top: 0;
            left: 0;
        }
        overflow: hidden;
        transition: #{$header-transition};
        %header-collapse-navigation-item,
        #{$collapse-selectors} {
            opacity: 0;
            visibility: hidden;
            transition: #{$header-item-transition};
        }
        &.open {
            transition: #{$header-transition-open};
            min-height: $header-navigation-min-height;
            %header-collapse-navigation-item,
            #{$collapse-selectors} {
                opacity: 1;
                visibility: visible;
                transition: #{$header-item-transition-open};   
            }
        }
    }
    @include breakout($sticky-header-height) {
        @media screen and (min-height: $header-height-breakpoint) {
            $offset: get-breakpoint-value($header-height, $breakpoint) - $value;
            #{$header-inner-selector}.open {
                @if $offset != 0 and $value != 0 {
                    height: calc(100vh + #{$offset} - #{$header-navigation-reserved-height});
                } @else {
                    height: calc(100vh - #{$header-navigation-reserved-height});
                }
            }
            .header:not(.is-stuck) #{$header-inner-selector}.open {
                @if $value != 0 {
                    margin-top: -#{$offset};
                }
            }
        }
    }
}

@mixin menu-button($color: #fff) {
    position: relative;
    width: 24px;
    height: 3px;
    background-color: $color;
    text-indent: 200%;  
    overflow: hidden;
    padding: 23px 12px 22px;
    box-sizing: content-box;
    background-clip: content-box;
    transition: background .3s ease;
    white-space: nowrap;
    &::after, &::before {
        display: block;
        left: 12px;
        content: '';
        height: 3px;
        position: absolute;
        width: 24px;
        background-color: $color;
        transform-origin: center;
        transition: background .3s ease,
                    transform .3s ease,
                    top .3s ease,
                    bottom .3s ease;
    }
    &::before {
        top: 16px;
    }
    &::after {
        bottom: 15px;
    }
}
@mixin menu-button-open {
    background-color: transparent;
    &::before, &::after {
        // background-color: $primary-color;
        // left: 12px;
    }
    &::before {
        top: 23px;
        transform: rotate(45deg);
    }
    &::after {
        bottom: 22px;
        transform: rotate(-45deg);
    }
}
@mixin slide-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    .nav-primary,
    .nav-home-title {
        transition: transform .3s ease;
    }
    .nav-home-title {
        order: 1;
    }
    .nav-primary {
        order: 2;
        position: relative;
    }
    .social {
        order: 3;
    }
    .nav-home-title,
    .nav-section-title {
        display: flex;
        visibility: hidden;
        flex-shrink: 0;
    }
    .nav-home-title {
        visibility: visible;
        transform: translateX(-100%);
    }
    .current + .nav-home-title {
        transform: translateX(0%);
    }
    .nav-primary,
    .sub-menu {
        width: 100%;
    }
    .sub-menu {
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        display: none;
        overflow: hidden;
    }
    .nav-section-title {
        position: absolute;
        bottom: 100%;
        left: 100%;
        right: -100%;
    }
    .sub-menu.current,
    .sub-menu.previous,
    .sub-menu.contains-current {
        display: block;
    }
    .sub-menu.current,
    .sub-menu.contains-current {
        overflow: visible;
    }
    .sub-menu.current + .nav-section-title,
    .sub-menu.previous + .nav-section-title {
        visibility: visible;
    }
}
@mixin slide-menu-icons($height: 12px, $x-space: $baseline / 2, $icon-size: $height, $border-width: 2px, $grow: false) {
    li:not(.icon) > a {
        display: flex;
        align-items: center;
        &::before,
        &::after {
            content: '';
            display: block;
            box-sizing: border-box;
            align-self: center;
            width: $icon-size;
            height: $icon-size;
            border-color: inherit;
            flex-shrink: 0;
            margin: calc((#{$height} - #{$icon-size}) / 2);
        }
        &::before {
            margin-right: $x-space;
        }
        &::after {
            @if $grow {
                margin-left: auto;
            } @else {
                margin-left: $x-space;
            }
        }
    }
    .has-sub-menu > a:not(.nav-section-title)::after {
        border-top: $border-width solid;
        border-right: $border-width solid;
        transform: rotate(45deg);
    }
    .nav-section-title {
        &::before {
            border-top: $border-width solid;
            border-right: $border-width solid;
            transform: rotate(225deg);
        }
    }
    .is-active {
        &::before {
            background-color: white;
            border-radius: 100%;
        }
    }
}
@mixin flyout-menu-base() {
    .has-sub-menu {
        position: relative;
    }
    .sub-menu {
    }
    @include flyout-link-selector {
    }
    @include flyout-menu-selector {
        z-index: 1;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        transform: scaleY(0);
        transform-origin: top left;
        > li {
            opacity: 0;
        }
        .sub-menu {
            top: 0;
            left: 100%;
            transform: scaleX(0);
        }
    }
    @include flyout-menu-open-selector {
        transform: none;
        & > li {
            opacity: 1;
        }
    }
    .sub-menu:hover,
    .has-sub-menu:hover > .sub-menu,
    .has-sub-menu:focus-within > .sub-menu,
    .menu-open > .sub-menu {
    }
}

@mixin flyout-menu-selector($prefix: '') {
    #{$prefix}.sub-menu {
        @content;
    }
}
@mixin flyout-menu-open-selector($prefix: '') {
    #{$prefix}.sub-menu:hover,
    #{$prefix}.has-sub-menu:hover > .sub-menu,
    #{$prefix}.menu-open > .sub-menu {
        @content;
    }
    #{$prefix}.has-sub-menu:focus-within > .sub-menu {
        @content;
    }
}
@mixin flyout-link-selector($prefix: '') {
    #{$prefix}.has-sub-menu > a {
        @content;
    }
}
@mixin flyout-link-open-selector($prefix: '') {
    #{$prefix}.has-sub-menu > a:focus,
    #{$prefix}.has-sub-menu:hover > a,
    #{$prefix}.menu-open > a {
        @content;
    }
    #{$prefix}.has-sub-menu:focus-within > a {
        @content;
    }
}

@mixin flyout-menu-full(
    $transition-time: .6s,
    $easein: cubic-bezier(.8,0,1,1),
    $easeout: cubic-bezier(0,0,0.2,1),

    $primary-text-color: $text-color-white,
    $primary-background-color: $primary-color,

    $secondary-text-color: $text-color-white,
    $secondary-background-color: $secondary-color
) {
    @include flyout-menu-base();
    .nav-list {
        .has-sub-menu > a {
            position: relative;
            overflow: hidden;
            z-index: 0;
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }
        & > .has-sub-menu {
            &:hover > a::before,
            &:focus-within > a::before,
            &.menu-open > a::before {
                transform: translateY(0);
            }
        }
    }
    .nav-primary > .has-sub-menu {
        & > a::before {
            transform: translateY(-100%);
            background-color: $primary-background-color;
        }
        &:hover > a,
        &:focus-within > a,
        & > a:focus,
        &.menu-open > a {
            color: $primary-text-color;
            &::before {
                transform: none;
            }
        }
    }
    .nav-secondary > .has-sub-menu {
        & > a::before {
            transform: translateX(-100%);
            background-color: $secondary-background-color;
        }
        &:hover > a,
        & > a:focus,
        &:focus-within > a,
        &.menu-open > a {
            color: $secondary-text-color;
            &::before {
                transform: none;
            }
        }
    }

    .sub-menu {
        transition: transform $transition-time * 2/3 $easein;
        & > li {
            transition: opacity $transition-time / 4 $transition-time / 4;
        }
    }
    .sub-menu:hover,
    .has-sub-menu:hover > .sub-menu,
    .has-sub-menu:focus-within > .sub-menu,
    .menu-open > .sub-menu {
        transition: transform $transition-time * 2/3 $transition-time / 3 $easeout;
        & > li {
            transition: opacity $transition-time / 4 $transition-time * 3/4;
        }
    }
    .has-sub-menu > a {
        transition: color $transition-time / 3 $transition-time * 2/3 $easeout;
        &::before {
            transition: transform $transition-time / 3 $transition-time * 2/3 $easeout;
        }
    }
    .has-sub-menu > a:focus,
    .has-sub-menu:hover > a,
    .sub-menu:focus-within > a,
    .menu-open > a {
        transition: color $transition-time / 3 $easein;
        &::before {
            transition: transform $transition-time / 3 $easein;
        }
    }
}
@mixin sitemap-basic($baseline: $baseline, $gutter: 1rem, $list-padding: .5rem) {

    a {
        display: block;
    }
    .nav-list {
        // max-width: $content-width;
        // margin: auto;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        // margin-top: $baseline * -1;
        & > li {
            flex-grow: 1;
            flex-basis: auto;
            width: auto;
            margin: 0 $gutter * 2 $baseline * 2 0;
            & > a {
                // @extend %header-b !optional;
                padding: 0 $list-padding / 2 calc(#{$baseline / 2} - 1px) $list-padding / 2;
                border-bottom: 1px solid;
            }
        }
        & > li.has-sub-menu {
            & > a {
                // margin-bottom: $baseline / 2;
            }
        }
    }
    .sub-menu {
        margin-left: $list-padding / 2;
        margin-top: $baseline;
        li {
            margin-top: $baseline / 2;
        }
        .sub-menu {
            margin-top: 0;
            margin-left: $list-padding;
            a {
                line-height: 1.3;
            }
            li{
                margin-top: $baseline / 4;
            }
        }
    }
}
@mixin side-nav-basic (
    $side-nav-shadow-color: $secondary-color,
    $side-nav-color: inherit,
    $side-nav-color-hover: $secondary-color,
    $side-nav-color-active: $side-nav-shadow-color,
    $gutter: $gutter,
    $baseline: $baseline
){

    list-style: none;
    padding: 0;
    // margin: 2 * $baseline 0;

    li {
        
        // padding: $baseline * .25 0;
        // margin-bottom: $baseline / 2;
        margin-top: $baseline / 2;
    }
    a {
        display: inline-block;
        padding-top: $baseline * .75;
        padding-bottom: $baseline * .75;
        line-height: $baseline * 1.5;
        color:$side-nav-color;
        @include breakout($gutter) {
            padding-left: $value;
        }
    }
    a:hover,
    a:focus { 
        color: $side-nav-color-hover;
    }
    .is-active > a {
        color: $side-nav-color-active;
        box-shadow: -1px 0 0 $side-nav-shadow-color;
    }
    .has-sub-menu ul {
        @include breakout($gutter) {
            margin-left: $value;
        }
    }

    .nav-tertiary {
        li {
            line-height: 1.35;
        }

        a {
            font-size: 0.875em;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.3; 
        }

    }
}
@mixin pagination {
    display: flex;
    justify-content: center;
    .pagination-button-list {
        @include unstyled-list;
        display: flex;
        max-width: 100%;
        // justify-content: space-between;
        &::before {
            content: '';
            order: 2;
            display: block;
            width: 4rem;
            flex-shrink: 1;
        }
        & > * {
            flex-shrink: 0;
        }
    }
    a {
        color: $text-color-regular;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .back {
        order: 1;
        a::before {
            content: '↵';
            display: inline-block;
            transform: rotate(90deg);
            margin-right: .5rem;
        }
    }
    .previous {
        // margin-right: 3 * $baseline;
        order: 3;
        padding-right: .5rem;
        margin-right: .5rem;
        border-right: 1px solid $text-color-regular;
        a::before {
            content: '❬';
            display: inline;
            margin-right: .5rem;
        }
    }
    .next {
        order: 4;
        a::after {
            content: '❭';
            display: inline;
            margin-left: .5rem;
        }
    }
    li.disabled {
        color: rgba($text-color-regular, .6);
    }
}
@mixin breadcrumbs(
    $margin: .75em,
    $delimiter: "»"
) {
    ul {
        @include unstyled-list;
        display: flex;
        flex-wrap: wrap;
    }
    li {
        margin-right: $margin;
        display: flex;
        &:not(:first-child)::before {
            content: $delimiter;
            display: inline-block;
            margin-right: $margin;
        }
    }
    a {
        display: block;
    }
}

