@mixin global-styles(
    $scroll-type: scroll,
) {
    //applied to a <meta> tag, to be used in js
    .foundation-mq {
        font-family: '#{-zf-bp-serialize($breakpoints)}';
    }

    html {
        overflow-y: $scroll-type;
        box-sizing: border-box;
    }

    // Set box-sizing globally to handle padding and border widths
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    // Default body styles
    body {
        padding: 0;
        margin: 0;
    }

    figure {
        margin: 0;
    }

    a {
        text-decoration: none;
    }

    img {
        // Grid defaults to get images and embeds to work properly
        max-width: 100%;
        height: auto;
        -ms-interpolation-mode: bicubic;
        // Get rid of gap under images by making them display: inline-block; by default
        display: inline-block;
        vertical-align: middle;
    }

    // Make select elements are 100% width by default
    select {
        width: 100%;
    }

    // Reset <button> styles created by most browsers
    button {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        padding: 0;
        border: 0;
        line-height: 1;
    }

    // Internal classes to show/hide elements in JavaScript
    .is-visible {
        display: block !important;
    }
    .is-hidden {
        display: none !important;
    }


    .site {
        min-height: 100vh;
    }
    .unstyled-list {
        @include unstyled-list;
    }
    .pre {
        white-space: pre-wrap;
    }

    .sb-error-page {
        pre {
            white-space: pre-wrap;
        }
    }
}
