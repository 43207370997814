@mixin team-list(
    $image-size: (
        small: $baseline * 4,
        // smallplus: $baseline * 6,
        smallplus: 100%,
    ),
) {
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                smallplus: 2,
                medium: 3,
                large: 4
            ),
            $baseline: (
                small: $baseline,
                large: $baseline * 1.5,
            ),
            $gutter: (
                small: $gutter,
                smallplus: $gutter,
                large: $gutter * 2
            ),
            $shrink-wrap: true,
        );
    }
    .head-link {
        display: block;
        color: inherit;
    }
    .head {
        display: flex;
        .image {
            @include object-fit;
            @include breakout($image-size) {
                width: $value;
            }
            position: relative;
            flex-shrink: 0;
            align-self: start;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            &::before {
                content: '';
                display: block;
                padding-bottom: 100%;
            }
        }
        .text-group {
            margin-left: $gutter;
            align-self: center;
        }
        .title {
            @include typography(header-d);
        }
        .summary {
            // @include typography(header-e);
            color: inherit;
            line-height: 1.25;
        }
        @include breakpoint(smallplus up) {
            flex-direction: column;
            .text-group {
                margin-left: 0;
                margin-top: $baseline * 1.5;
                text-align: center;
            }
        }
    }
    .expander-body-cont {
        display: none;
    }
    .item-wrapper {
        @include breakout($section-padding) {
            margin-bottom: $value / 2;
        }
    }
}