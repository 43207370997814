@import 'site:variables';
@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/typography';

@import 'site:item-mixins';
@import 'site:page-mixins';

$home-header-columns: (
    small: 1 12,
    460px: 1 11,
    medium: 1 9,
    large: 2 11,
    xxlarge: 3 10
);

.layout-home-home {
    // @include page-layout-landing;
    .header-banner {
        overflow: hidden;
        @include breakout($header-height) {
            @include grid-template-rows($value auto 1fr auto);
        }
        .profile-avatar {
            z-index: 2;
            @include grid-row(1, 3);
            @include grid-column(12, 3);
            @include grid-align-self(start);
            opacity: 0.4;
            @include breakout($header-height) {
                margin-top: $value + $baseline * 2;
            }
            @include breakout($content-gutters) {
                margin-left: $value;
                margin-right: $value;
            }
            // @include breakpoint(smallplus up) {
            //     margin-top: $baseline * 2;
            // }
            @include breakpoint(medium up) {
                @include grid-column(11, 3);
                // margin-top: $baseline * 4;
                opacity: 1;
                margin-right: $gutter * 2;
            }
            @include breakpoint(large up) {
                @include grid-column(1, 2);
                align-items: flex-end;
            }
            @include breakpoint(xxlarge up) {
                @include grid-column(2, 2);
                align-items: flex-start;
            }
        }
        .hero-page,
        .user-links {
            z-index: 3;
        }
        .hero-page {
            @include breakout($home-header-columns) {
                @include grid-column(nth($value, 1), nth($value, 2), true);
            }
        }
        .user-links {
            @include grid-row(4);
            // @include grid-align-self(end);
            @include breakout($home-header-columns) {
                @include grid-column(nth($value, 1), 12 - nth($value, 1), true);
            }
        }
    }
    .hero-page {
        @include breakpoint(small down) {
            .home-schedule {
                margin-left: $gutter * -1;
                margin-right: $gutter * -1;
            }
        }
        @include breakpoint(large up) {
            @include grid;
            @include grid-template-columns(1fr 1.2fr);
            @include grid-template-rows(auto 1fr);
            .title,
            .description {
                @include grid-column(1);
                max-width: 600px;
            }
            .title {
                @include grid-row(1);
            }
            .description {
                @include grid-row(2);
            }
            .home-schedule {
                @include grid-row(1, 2);
                @include grid-column(2);
                @include grid-justify-self(end);
                @include grid-align-self(center);
                margin-top: 0;
                max-width: 600px;
                width: calc(100% - #{$gutter * 2});
                margin-left: $gutter * 2;
            }
        }
    }
}
.item-group.home-schedule {
    @include paper;
    padding: $baseline * 1.5;
    overflow: hidden;
    .message.success {
        padding: $baseline * 1.5;
        margin-top: $baseline * -1.5;
        margin-left: $baseline * -1.5;
        margin-right: $baseline * -1.5;
        margin-bottom: $baseline;
        background-color: $primary-color;
        color: $text-color-white;
        p {
            margin-top: $baseline / 2;
            margin-bottom: 0;
        }
    }
    .message.success::after {
        display: none;
    }
}
.profile-avatar {
    $avatar-size: (
        small: 8rem,
        smallplus: 10rem,
        medium: (160/$em) * 1rem,
        xlarge: (196/$em) * 1rem
    );
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    @include breakout($avatar-size) {
        .avatar {
            width: $value;
        }
        &::after {
            width: $value;
            height: $value * 3/4;
            margin-top: $value / -6;
        }
    }
    .avatar {
        z-index: 2;
        position: relative;
    }
    &::after {
        content: '';
        position: relative;
        z-index: 1;
        display: block;
        opacity: .5;
        background-image: url('/assets/images/avatar-circles.svg');
        background-position: right top;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.header-banner .user-links {
    display: none;
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
    margin-bottom: $baseline * 2;
    font-size: 20rem/$em;
    font-weight: $semibold;
    pointer-events: auto;
    @include breakpoint($mobile-breakpoint-up up) {
        display: flex;
    }
    .item-group {
        margin: 0;
        margin-right: $baseline * 2;
    }
    a {
        display: block;
    }
}
