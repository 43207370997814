//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';

@import 'callouts:mixins';
@import 'site:page-mixins';
@import 'mixins';

// .layout-articles-list {
//     @include breakpoint(large up) {
//         .pager.primary-image {
//             @include grid-row(1);
//             @include grid-column(8, 5, true);
//         }
//         .article-list {
//             @include grid-row(1);
//             @include grid-column(1, 7, true);
//             max-width: none;
//         }
//     }
//     @include breakout($section-padding, large) {
//         .pager.primary-image,
//         .article-list {
//             margin-bottom: $value;
//         }
//     }
// }
.article-grid {
    @include article-grid;
}

.secondary-navigation ~ .lister.article-grid {
    @include breakpoint($mobile-breakpoint-up up) {
        @include content-columns-secondary-full;
    }
}

.layout-expanders-list {
    @include page-layout-center;
}

.article-list {
    @include article-icons;
    @include basic-article-list;
    @include article-expander-enhance;
    // @include grid-justify-self(center);
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
        width: calc(100% - #{$value * 2});
    }
    // max-width: 80ch;
    .head .image img {
        border-radius: 50%;
    }
    a.head-link:hover,
    a.head-link:focus {
        .head .title {
            color: map-get(get-article-icons-props(), hover-color);
        }
    }
    .item-wrapper {
        @include breakout($section-padding) {
            margin-bottom: $value / 3;
        }
    }
    .items {
        @include breakout(map-get(get-article-props(), head-padding)) {
            margin-top: $value * -1;
        }
    }
    .expander .head::after {
        align-self: flex-start;
    }
    .expander-bodyu {
        margin-top: $baseline;
        & > * {
            padding-right: 0;
        }
    }
    .item.no-image .expander-bodyu .text {
        @include breakpoint(medium up) {
            margin-left: $baseline * 2;
            margin-right: $baseline * 2;
        }
    }
    button.head-link {
        text-align: left;
        width: 100%;
    }
    .head .meta {
        margin-top: 0;
        color: $text-color-regular;
        .categories {
            flex-wrap: wrap;
            display: flex;
            margin-top: $baseline / 2;
        }
        .category {
            margin-right: .5rem;
            color: $text-color-dark;
            font-weight: $semibold;
            &:not(:last-child)::after {
                content: ',';
                font-weight: $normal;
            }
            &:last-child {
                margin-right: 1rem;
            }
        }
        .date-posted {
            margin-top: $baseline / 2;
            .icon {
                margin-right: .5em;
                font-size: 1rem;
                vertical-align: -.075em;
            }
        }
    }

    .item.no-image {
        padding-top: 0;
        padding-bottom: 0;
        // padding-left: 2rem;
        margin-top: $baseline / 2;
        margin-bottom: $baseline / 2;
        // margin-left: -2.5rem;
        // border-left: .5rem solid blue;
        @include breakout($outer-gutters) {
            $paddingLeft: min($value, 32px);
            margin-left: $paddingLeft * -1;
            padding-left: $paddingLeft;
        }
        $colors: $purple, $orange, $teal;
        @each $color in $colors {
            &:nth-child(#{length($colors)}n + #{index($colors, $color)}) {
                border-left: .5rem solid $color;
            }
        }
    }
}
.secondary ~ .primary .article-list {
    @include grid-justify-self(start);
}

.page-layout-articles-root {
    .header-banner {
        .hero-page {
            margin-bottom: 0;
        }
        .article-search {
            @include grid-row(3);
            @include grid-column(1, 12, true);
            @include breakout($header-columns) {
                @include grid-column(nth($value, 1), 12 - ((nth($value, 1) - 1) * 2), true);
            }
            margin-top: $baseline * 2;
        }
        .category-navigation {
            @include grid-row(4);
            @include breakout($header-columns) {
                @include grid-column(nth($value, 1), 12 - ((nth($value, 1) - 1) * 2), true);
            }
            @include breakout($section-padding) {
                margin-bottom: $value;
            }
        }
        @include breakpoint(large up) {
            .hero-page {
                // grid-column-end: 8;
            }
            .article-search {
                @include grid-row(2);
                @include grid-column(9, 4, true);
            }
        }
        @include breakpoint(xlarge up) {
            .article-search {
                @include grid-column(10, 3, true);
            }
        }
    }
}

.layout-courses-list,
.page-layout-articles-root {
    .item-group.article-list {
        // width: 100%;
        max-width: none;
        // margin-left: 0;
        // margin-right: 0;
        .head-link {
            display: flex;
            align-items: flex-start;
            &:hover,
            &:focus {
                color: $anchor-color;
            }
            & > .icon {
                @include typography(header-c);
                flex-shrink: 0;
                @include breakout(map-get(get-article-props(), head-padding)) {
                    margin-top: $value + .125rem;
                }
            }
        }
        .head {
            flex-grow: 1;
        }
        .head::after {
            display: none;
        }
        .items {
            @include flex-columns(
                $per-row: (
                    small: 1,
                    medium: 2,
                ),
                $baseline: 0,
                $gutter: (
                    small: $baseline,
                    medium: $baseline * 2
                ),
                $shrink-wrap: true
            );
        }
    }
}

.page-layout-article-page {
    @include page-layout-article-page;
}

.item-group.article-search,
.item-group.category-navigation,
.item-group.article-meta {
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
}
.breadcrumbs {
    @include typography(header-c);
    display: flex;
    flex-wrap: wrap;
    margin-top: $baseline;
    .breadcrumb {
        display: flex;
        align-items: center;
        margin-top: $baseline;
    }
    .breadcrumb-link {
        color: $anchor-color;
        &:hover,
        &:focus {
            outline: 0;
            color: $anchor-color-hover;
        }
    }
    .icon {
        font-size: .75rem;
        margin-left: .5rem;
        margin-right: .5rem;
    }
}
.category-navigation .categories {
    display: flex;
    flex-wrap: wrap;
    margin-top: $baseline;
    .category {
        display: block;
        flex-grow: 1;
        margin-bottom: $baseline;
        padding: 0.375rem 1rem;
        line-height: $baseline * 2;
        border-radius: $baseline * 2;
        background-color: rgba($gray300, .5);
        margin-right: $baseline;
        color: $text-color-regular;
        text-align: center;
        &:hover,
        &:focus {
            background-color: rgba($teal-light, .9);
            outline: none;
        }
    }
    @include breakpoint($mobile-breakpoint-up up) {
        display: block;
        column-width: 14rem;
        .category {
            padding: 0 .75rem;
            width: -moz-fit-content;
            width: fit-content;
            border-radius: $baseline;
            text-align: left;
        }
    }
}

.article-meta {
    display: flex;
    flex-wrap: wrap;
    margin-top: $baseline / 2;
    opacity: .75;
    .categories {
        display: flex;
        flex-wrap: wrap;
        margin-top: $baseline / 2;
    }
    .category {
        margin-right: .75rem;
        color: $text-color-dark;
        font-weight: $semibold;
        &:hover,
        &:focus {
            color: $anchor-color;
        }
        &:not(:last-child)::after {
            content: ',';
            font-weight: $normal;
        }
        &:last-child {
            margin-right: 1.5rem;
        }
    }
    .date-posted {
        margin-top: $baseline / 2;
        .icon {
            font-size: 1rem;
            margin-right: .5rem;
            vertical-align: -.075em;
        }
    }
}