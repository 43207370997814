//these mixins could be moved to a shared directory if/when they're needed on a site front end
@include dialog-window;
@include dialog-configure;

.dialog.tooltip {
  @include dialog-tooltip;
  @include dialog-positioned;
}

.dialog.sblib {
  @include dialog-full;
}

.sb-context-menu {
  @include context-menu;
}

.dialog.sb-context-menu {
  @include dialog-positioned;
}

//for overflowed text
.sb-tooltip {
  @include dialog-tooltip-compact;
}
