@mixin item-gutters {
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
}

@mixin item-wrapper-full-width {
    .item-wrapper {
        @include grid;
        @include grid14;
        @include make-grid-rows(2);
        & > * {
            @include header-columns();
            @include breakout($content-gutters) {
                margin-left: $value;
                margin-right: $value;
            }
        }
    }
}

@mixin session-fab {
    @include grid;
    @include grid14;
    z-index: 28;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20%);
    transition: opacity .1s,
                transform .2s ease;
    @include breakpoint(medium up) {
        bottom: $baseline * 3;
    }
    @include breakpoint(xlarge up) {
        bottom: $baseline * 4;
    }
    .button {
        @include grid-row(1);
        @include grid-column(1, 12, true);
        // @include header-columns();
        @include grid-justify-self(end);
        padding: .75em 1.2em;
        box-shadow: 0 (2/$em) * 1em 0 $anchor-color-hover,
                    0 2px 9px rgba(0,0,0,0.3);
        @include breakpoint(smallplus down) {
            @include grid-column(1, 14);
            border-radius: 0;
            width: 100%;
        }
    }
    &.active:not(.form-visible) {
        opacity: 1;
        transform: none;
        .button {
            pointer-events: auto;
        }
    }
}

@mixin hero-banner-height($banner-height, $tail-ratio: $banner-clip-ratio) {
    @include breakout($banner-height) {
        min-height: $value;
    }
    // @include breakout($banner-height) {
    //     @if $tail-ratio {
    //         @include grid-template-rows(minmax(#{$value * ($tail-ratio - 1) / $tail-ratio}, 1fr));
    //     } @else {
    //         @include grid-template-rows(minmax(#{$value}, 1fr));
    //     }
    //     --banner-height: #{$value};
    // }
    // @if $tail-ratio {
    //     --tail-height: calc(var(--banner-height) / #{$tail-ratio});
    // } @else {
    //     --tail-height: 0rem;
    // }
    // // @include breakout($banner-height) {
    // //     .hero-page {
    // //         margin-top: max($value / 8, $baseline);
    // //         margin-bottom: $value / 8;
    // //     }
    // // }
    // @if not $banner-clip-path {
    //     @supports (--css: variables) {
    //         margin-bottom: calc(var(--tail-height) * -1);
    //         grid-template-rows: minmax(var(--banner-height), 1fr);
    //         padding-bottom: calc(var(--tail-height));
    //     }
    // } @else {    
    //     @supports (--css: variables) and (clip-path: polygon(0 0, 100% 0, 100% 100%)) {
    //         margin-bottom: calc(var(--tail-height) * -1);
    //         grid-template-rows: minmax(var(--banner-height), 1fr);
    //         padding-bottom: calc(var(--tail-height));
    //     }
    // }
}

// @mixin hero-banner-tail {
//     @include breakout($outer-gutters) {
//         --outer-gutter: #{$value};
//     }
//     --tail-offset: 0px;
//     @include breakpoint(large up) {
//         --tail-offset: var(--outer-gutter);
//     }
//     @include breakpoint(xxlarge up) {
//         // --tail-offset: calc(var(--outer-gutter) + (100% - (var(--outer-gutter) * 2)) * 1/12);
//     }
//     @supports (--css: variables) and (clip-path: polygon(0 0, 100% 0, 100% 100%)) {
//         clip-path: $banner-clip-path;
//     }
// }

@mixin hero-banner-color($background-color: $banner-background-color, $color: $banner-text-color) {
    background-color: $background-color;
    color: $color;
    .pager.page_image::after {
        background: linear-gradient(
            to bottom,
            rgba($background-color, .4),
            rgba($background-color, .4) 4rem,
            rgba($background-color, 0) 12rem
        );
    }
}
@mixin hero-banner($banner-height: $banner-height) {
    @include hero-banner-height($banner-height);
    // @include hero-banner-tail;
    @include breakout($header-height) {
        @include grid-template-rows($value auto);
    }
    @include hero-banner-color;
    align-content: start;
    position: relative;
    // pointer-events: none;
    .pager.page_image {
        // @include grid-row(1, 2);
        // @include grid-column(1, 14);
        @include object-fit;
        z-index: 1;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: $banner-background-opacity;
        }
    }
    & > * {
        position: relative;
        z-index: 2;
        // pointer-events: auto;
    }
    .hero-page {
        @include grid-row(2);
        @include grid-align-self(start);
        @include header-columns;
        @include item-gutters;
        position: relative;
        // @include breakout($header-height) {
        //     margin-top: $value;
        // }
        margin-top: $baseline * 2;
        @include breakout($section-padding) {
            margin-bottom: $value;
        }
    }
    .breadcrumbs {
        @include grid-row(3);
        @include breakout($header-columns) {
            @include grid-column(nth($value, 1), 12 - ((nth($value, 1) - 1) * 2), true);
        }
        @include breakout($content-gutters) {
            margin-left: $value;
            margin-right: $value;
        }
        margin-bottom: $baseline * 2;
    }
    .title,
    .description,
    .text {
        color: inherit;
    }
}
%slide-nav-arrow {
    height: $baseline * 2;
    width: $baseline * 2;
    margin: $baseline;
    border-top: $baseline / 3 solid;
    border-right: $baseline / 3 solid;
    transform: rotate(45deg);
    opacity: .75;
}
@mixin content-slideshow(
    $height: (
        small: (320/$em) * 1rem,
        xlarge: (400/$em) * 1rem,
        xxlarge: (480/$em) * 1rem
    )
) {
    @include slideshow($nav-button-placeholder: "%slide-nav-arrow");
    position: relative;
    .slideshow-stage {
        margin-bottom: $baseline * 3;
        @include breakpoint(medium up) {
            margin-bottom: $baseline * 3.5;
        }
    }
    .slideshow-cont,
    .slide,
    .head,
    .head-image {
        display: flex;
        flex-grow: 1;
    }
    .image {
        @include object-fit((
            object-fit: contain
        ));
        display: flex;
        background-color: $content-background-color;
        height: auto;
        flex-grow: 1;
        @include breakout($height) {
            max-height: $value;
        }
    }
    .slideshow-navigation {
        @include content-slideshow-navigation-pips;
        z-index: 5;
        position: absolute;
        left: 0;
        max-width: 70%;
        bottom: 0;
        // top: calc(100% + #{$baseline});
        // @include breakpoint(medium up) {
        //     top: calc(100% + #{$baseline * 1.5});
        // }
    }
}
@mixin content-slideshow-navigation-pips {
    pointer-events: none;
    ul {
        display: flex;
        flex-wrap: wrap;
    }
    li {
        box-sizing: content-box;
        pointer-events: auto;
        cursor: pointer;
        width: 2em;
        height: (2/$em) * 1em;
        background-color: $light-gray;
        background-clip: content-box;
        padding-top: calc(#{$baseline} - #{(1/$em) * 1rem});
        padding-bottom: calc(#{$baseline} - #{(1/$em) * 1rem});
        margin-right: (4/$em) * 1em;
        transition: background-color .3s;
        &.current-slide {
            background-color: $anchor-color;
        }
        &:hover,
        &:focus {
            background-color: $anchor-color-hover;
        }
    }
}

@mixin dropdown-menu(
    $align: end,
    $background-color: $white,
    $shadow: 0 2px 6px rgba(0,0,0,0.2)
) {
    z-index: 20;
    position: relative;
    display: grid;
    @include grid-template-columns(auto);
    @include grid-template-rows(auto 0);
    margin-right: .85rem;
    .dropdown-menu-button {
        z-index: 2;
        @include grid-column(1);
        @include grid-row(1);
        position: relative;
        display: flex;
        align-items: center;
        margin-right: -.85rem;
        .icon {
            font-size: .6rem;
            margin-left: .25rem;
        }
    }
    .dropdown-menu-nav {
        a {
            display: block;
            padding: $baseline / 4 $baseline;
        }
        // .icon {
        //     background-color: currentColor;
        // }
    }
    .dropdown-menu-content {
        @include grid-column(1);
        @include grid-row(2);
        position: absolute;
        top: 1rem;
        width: 12rem;
        width: max-content;
    }

    //visibility
    .dropdown-menu-content {
        transform-origin: top;
        transform: translateY(-25%);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: transform .3s ease-in,
                    opacity .3s ease,
                    visibility 0s .3s;
    }
    .opened + .dropdown-menu-content {
        visibility: visible;
        pointer-events: auto;
        transform: none;
        opacity: 1;
        transition: transform .3s cubic-bezier(0, 0, 0.1, 1),
                    opacity .3s ease;
    }

    @include dropdown-menu-bubble($background-color, $shadow);
    @include dropdown-menu-align($align);
}

@mixin dropdown-menu-bubble(
    $background-color: $white,
    $shadow: 0 2px 6px rgba(0,0,0,0.2),
    $selector: '.dropdown-menu-content'
) {
    #{$selector} {
        padding: $baseline / 2 0;
        box-shadow: $shadow;
        &::before {
            z-index: 1;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 1rem;
            height: 1rem;
            transform-origin: center;
            transform: translate(-50%, -50%) rotate(45deg);
            background-color: $background-color;
            box-shadow: $shadow;
        }
        &::after {
            z-index: 2;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $background-color;
            border-radius: (2/$em) * 1rem;
        }
        & > * {
            z-index: 3;
            position: relative;
        }
        
    }
}

@mixin dropdown-menu-align($align, $selector: '.dropdown-menu-content') {
    #{$selector} {
        @include grid-justify-self($align);
        @if $align == start {
            margin-left: -.5rem;
        } @else if $align == end {
            margin-right: -.85rem;
        }
        &::before {
            @if $align == center {
                left: 50%;
            } @else if $align == start {
                left: .5rem;
                transform: translate(50%, -50%) rotate(45deg);
            } @else if $align == end {
                left: auto;
                right: .85rem;
            }
        }
    }
}

@mixin paper {
    border-radius: (4/$em) * 1rem;
    background-color: $white;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 
        0px 1px 1px 0px rgba(0,0,0,0.14), 
        0px 1px 3px 0px rgba(0,0,0,0.12);
}
