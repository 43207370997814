//config
@import 'site:variables';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';
@import 'site:page-mixins';
@import 'site:item-mixins';
@import 'mixins';

.item-group.nav-overview {
    @include overview;
    @include overview-item;
}