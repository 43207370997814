//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';

@import 'articles:mixins';
@import 'callouts:mixins';
@import 'site:page-mixins';


.continuation-info {
    margin-top: 0px;
}

#primary-continuation-info li {
    padding-top: 7px;
    padding-bottom: 7px;
}