@mixin icon-double-arrow($svg-color: $primary-color){
    background-image: inline-svg(
        "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129'><g><path d='m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z' fill='#{$svg-color}'/></g></svg>"
    );
}
@mixin icon-arrow($svg-color: $primary-color){
    background-image: inline-svg(
        "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='-6 0 123 129'><g><path d='m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z' fill='#{$svg-color}'/></g></svg>"
    );
}
@mixin icon-download($svg-color: $primary-color){
    background-image: inline-svg(
        "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 471.2 471.2'><g><path d='M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8    c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8    C471.2,236.25,465.2,230.15,457.7,230.15z' fill='#{$svg-color}'/><path d='M226.1,346.75c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0l-62.7,62.8    V30.75c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v273.9l-62.8-62.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1    L226.1,346.75z' fill='#{$svg-color}'/></g></svg>"
    );
}
@mixin icon-plus($svg-color: $primary-color){
    background-image: inline-svg(
        "<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 128 128' xml:space='preserve'><style type='text/css'>.st0{stroke:#{$svg-color};stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;}</style><line class='st0' x1='16' y1='64' x2='112' y2='64'/><line class='st0' x1='64' y1='16' x2='64' y2='112'/></svg>"
    );
}
@mixin icon-minus($svg-color: $primary-color){
    background-image: inline-svg(
        "<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 128 128' xml:space='preserve'><style type='text/css'>.st0{stroke:#{$svg-color};stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;}</style><line class='st0' x1='16' y1='64' x2='112' y2='64'/></svg>"
    );
}
