@mixin content-columns {
    @include grid-column(1, 12, true);
    @include breakpoint(large up) {
        @include grid-column(2, 11, true);
    }
    @include breakpoint(xlarge up) {
        @include grid-column(2, 10, true);
    }
    @include breakpoint(xxlarge up) {
        // @include grid-column(2, 8, true);
    }
}
@mixin content-columns-secondary {
    @include grid-column(3, 10, true);
    @include breakpoint(large up) {
        @include grid-column(4, 8, true)
    }
    @include breakpoint(xlarge up) {
        @include grid-column(4, 7, true)
    }
    @include breakpoint(xxlarge up) {
        // @include grid-column(5, 6, true);
    }
}
@mixin content-columns-secondary-full {
    @include grid-column(3, 10, true);
    @include breakpoint(large up) {
        @include grid-column(4, 9, true)
    }
    @include breakpoint(xlarge up) {
        @include grid-column(4, 9, true)
    }
    @include breakpoint(xxlarge up) {
        // @include grid-column(5, 8, true);
    }
}
@mixin secondary-columns {
    @include grid-column(1, 2, true);
    @include breakpoint(large up) {
        @include grid-column(2, 2, true);
    }
    @include breakpoint(xxlarge up) {
        // @include grid-column(2, 2, true);
    }
}

$header-columns: (
    small: 1 12,
    460px: 1 10,
    medium: 1 8,
    large: 2 6,
    // xxlarge: 3 5
);

@mixin header-columns {
    @include breakout($header-columns) {
        @include grid-column(nth($value, 1), nth($value, 2), true);
    }
    // @include grid-column(2, 11, true);
    // @include breakpoint(360px up) {
    //     @include grid-column(3, 10, true);
    // }
    // @include breakpoint(500px up) {
    //     @include grid-column(3, 9, true);
    // }
    // @include breakpoint(medium up) {
    //     @include grid-column(2, 8, true);
    // }
    // @include breakpoint(large up) {
    //     @include grid-column(2, 6, true);
    // }
    // @include breakpoint(xxlarge up) {
    //     @include grid-column(3, 4, true);
    // }
}

@mixin page-layout-base {
    .primary,
    .header-banner {
        @include grid;
        @include grid14;
    }
    .header-banner {
        position: relative;
        z-index: 2;
    }
    .primary {
        position: relative;
        z-index: 1;
        // position: relative;
        // @include breakout($banner-height) {
        //     margin-top: $value / -6;
        // }
        @include make-grid-rows(6);
        & > * {
            @include content-columns;
            @include breakout($section-padding) {
                margin-top: $value;
                &:last-child {
                    margin-bottom: $value;
                }
            }
        }
        &:first-child {
            @include breakout($header-height) {
                padding-top: $value;
            }
        }
    }
    .secondary-navigation {
        display: none;
        @include secondary-columns();
       
    }
    @include breakpoint($mobile-breakpoint-up up) {
        .primary {
            .secondary-navigation {
                @include grid-row(2, 4);
                display: block;
                & ~ * {
                    @include content-columns-secondary;
                }
            }
        }
    }
}

$center-layout-columns: (
    small: 1 12,
    medium: 2 10,
    large: 3 8,
    xlarge: 4 6
);
@mixin page-layout-center {
    .header-banner .hero-page,
    .primary > * {
        @include breakout($center-layout-columns) {
            @include grid-column(nth($value, 1), nth($value, 2), true);
        }
    }
}

@mixin page-layout-full($minimal: false) {
    @if not $minimal {
        @include breakout($header-height) {
            padding-top: $value;
        }
    } @else {
        @include breakout($header-logo-height) {
            padding-top: $value + $baseline * 2.5;
        }
    }
    display: grid;
    @include grid14;
    & > * {
        @include grid-column(1, 12, true);
        @include breakout($section-padding) {
            margin-top: $value;
            &:last-child {
                margin-bottom: $value;
            }
        }
        @include breakout($content-gutters) {
            margin-left: $value;
            margin-right: $value;
        }
    }
}

@mixin modal-open {
    body {
        height: 100vh;
        overflow: hidden;
    }
}
