@function get-input-checkbox-colors($props: ()) {
    //defaults -> local props -> cascaded defaults -> local props
    $defaults: map-merge((
        text-color: $text-color-regular,
        input-border-color: $light-gray,
        input-border-focus-color: $text-color-regular,
        checkbox-icon-color: $white
    ), $props);

    @return map-merge((
        checkbox-color: map-get($defaults, text-color),
        checkbox-text-color: map-get($defaults, text-color),
        checkbox-focus-color: map-get($defaults, input-border-focus-color),
    ), $defaults);
}
@function get-input-checkbox-props($props: ()) {
    // base input defaults + label-top defaults + checkbox defaults -> cascaded checkbox defaults -> props

    $defaults: get-input-props(($props), input-label-top);
    $defaults: map-merge((
        // label-margin: $baseline / 2,
        checkbox-line-height: 1.25em,
        checkbox-size: 1em, 
        checkbox-border-width: 0.125em,
        checkbox-gap: .5em,
        checkbox-border-radius: 0
    ), $defaults);

    @return map-merge((
        checkbox-font-size: map-get($defaults, input-font-size),
        checkbox-offset: calc((#{map-get($defaults, checkbox-line-height)} - #{map-get($defaults, checkbox-size)}) / 2)
    ), $defaults);
}
@mixin input-checkbox-colors(
    $props: ()
) {
    $props: get-input-props($props, input-checkbox-colors);
    $label-selector: map-get($props, label-selector);

    input {
        & + #{$label-selector} {
            color: map-get($props, checkbox-text-color);
        }
        & + #{$label-selector}::before,
        & + #{$label-selector}::after {
            color: map-get($props, checkbox-color);
        }
        // &:focus + #{$label-selector}::before,
        &:focus + #{$label-selector}::after {
            color: map-get($props, checkbox-focus-color);
        }
    }
    [type="checkbox"]:checked {
        & + #{$label-selector}::after {
            @include sb-icon-check-invert(map-get($props, checkbox-icon-color));
        }
    }
}
@mixin input-checkbox(
    $props: ()
) {
    $props: get-input-props($props, input-checkbox);
    $label-selector: map-get($props, label-selector);

    $label-margin: map-get($props, label-margin);
    $line-height: map-get($props, checkbox-line-height); 
    $font-size: map-get($props, checkbox-font-size); 
    $checkbox-size: map-get($props, checkbox-size); 
    $checkbox-border-width: map-get($props, checkbox-border-width);
    $checkbox-border-radius: map-get($props, checkbox-border-radius);
    $checkbox-top-margin: map-get($props, checkbox-offset);
    $checkbox-gap: map-get($props, checkbox-gap);

    position: relative;
    margin-top: $label-margin;
    [type="radio"],
    [type="checkbox"] {
        position: absolute;
        width: 0px;
        height: 0px;
        overflow: hidden;
        margin: 0;
        opacity: 0;
        padding: 0;
        border: 0;
    }
    #{$label-selector} {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        font-size: $font-size;
        line-height: $line-height;
        &::before,
        &::after {
            content: '';
            display: block;
            width: $checkbox-size;
            height: $checkbox-size;
            margin-top: $checkbox-top-margin;
            flex-shrink: 0;
        }
        &::before {
            // position: absolute;
            // top: 0;
            // left: 0;
            order: -1;
            margin-right: -#{$checkbox-size};
            border-radius: 50%;
            background-color: currentColor;
            transform-origin: center;
            transform: scale(0);
            transition: color .2s, transform .15s;
        }
        &::after {
            order: -1;
            position: relative;
            flex-shrink: 0;
            margin-right: $checkbox-gap;
            border: $checkbox-border-width solid;
            transition: color .2s;
        }
    }
    .input-error {
        margin-top: .375em;
        margin-left: calc(#{$checkbox-size} + #{$checkbox-gap});
    }
    &.required .label-inner::after {
        content: "*";
        display:inline;
        vertical-align: middle;
        line-height: 0;
        margin-left: .4em;
        color: $required-color;
    }
    input:hover + #{$label-selector}::before {
        transform: scale(.2);
    }
    input:checked + #{$label-selector}::before {
        transform: scale(.4);
    }
    input:disabled + #{$label-selector} {
        opacity: .6;
        pointer-events: none;
    }
    [type="radio"] {
        & + #{$label-selector}::before {
        }
        & + #{$label-selector}::after {
            border-radius: 50%;
        }
    }
    [type="checkbox"] {
        & + #{$label-selector}::before {

            transition: color .2s, transform .15s .05s, border-radius .05s;
        }
        & + #{$label-selector}::after {
            background-clip: content-box; //results in better rendering
            background-size: $checkbox-size $checkbox-size;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: $checkbox-border-radius;
        }
    }
    [type="checkbox"]:checked {
        & + #{$label-selector}::before {
            transform: scale(1);
            border-radius: $checkbox-border-radius;
            transition: color .2s, transform .15s, border-radius .05s .10s;
        }
        & + #{$label-selector}::after {
            @include sb-icon-check-invert($white);
        }
    }
}
