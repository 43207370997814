$typography-defaults: (
    header-base: (
        font-family: $header-font-family,
        font-weight: $semibold,
        color: $text-color-regular,
        line-height: 1.2,
    ),
    text-base: (
        font-weight: $normal,
        line-height: 1.5,
        font-family: $body-font-family,
        color: $text-color-regular,
    ),
    header-e: (
        base: header-base,
        small: (
            font-size: #{14/$em}rem,
            line-height: $baseline * 2,
        ),
        medium: (
            font-size: 1rem,
        ),
    ),
    header-d: (
        base: header-base,
        small: (
            font-size: #{16/$em}rem,
            line-height: $baseline * 2,
        ),
        medium: (
            font-size: #{18/$em}rem,
        ),
    ),
    header-c: (
        base: header-base,
        small: (
            font-size: #{18/$em}rem,
        ),
        medium: (
            font-size: #{20/$em}rem,
        ),
    ),
    header-b: (
        base: header-base,
        small: (
            font-size: #{20/$em}rem,
        ),
        medium: (
            font-size: #{24/$em}rem,
        ),
    ),
    header-a: (
        base: header-base,
        small: (
            font-size: #{24/$em}rem,
        ),
        medium: (
            font-size: #{32/$em}rem,
        ),
    ),
    text-small: (
        base: text-base,
        small: (
            font-size: #{12/$em}rem,
        ),
        medium: (
            font-size: #{14/$em}rem,
        ),
    ),
    text-medium: (
        base: text-base,
        small: (
            font-size: #{14/$em}rem,
        ),
        medium: (
            font-size: #{16/$em}rem,
        ),
    ),
    text-large: (
        base: text-base,
        small: (
            font-size: #{16/$em}rem,
        ),
        medium: (
            font-size: #{18/$em}rem,
        ),
    ),
) !default;
$typography: () !default;
$typography: map-merge($typography-defaults, $typography);

@mixin typography($typography-key) {
    @if map-get($typography, $typography-key) != null {
        $styles: map-get($typography, $typography-key);
        @each $key, $value in $styles {
            @if $key == base {
                @include typography($value);
            } @elseif type-of($value) == map {
                @include breakpoint($key up) {
                    @each $prop, $prop-value in $value {
                        #{$prop}: $prop-value;
                    }
                }
            } @else {
                #{$key}: $value;
            }
        }
    } @else {
        @warn $typography-key + " typography style does not exist";
    }
}

@mixin base-content($container: 'body') {
    @if $container {
        #{$container} {
            @include typography('text-medium');
        }
    } @else {
        @include typography('text-medium');
    }
    a {
        color: $anchor-color;
        text-decoration: none;
        &:focus,
        &:hover {
            color: $anchor-color-hover;
        }
    }
    strong,
    b {
        font-weight: $bold;
    }
    ul {
        margin: 0;
    }
    p {
        margin: $baseline 0;
    }
    h1{
        margin: 0;
        @include typography('header-a');
    }
    h2 {
        margin: 0;
        @include typography('header-b');
    }
    h3 {
        margin: 0;
        @include typography('header-c');
    }
    h4 {
        margin: 0;
        @include typography('header-d');
    }

    .text-upper {
        text-transform: uppercase;
    }
    .text-nowrap {
        display: inline-block;
        margin-right: .5em;
    }
}
