//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';

@import 'admin:dialog-mixins';
@import 'admin:dialog';

@import 'articles:mixins';
@import 'callouts:mixins';
@import 'site:page-mixins';
@import 'site:item-mixins';

.page-request-form {
    .hidden {
        display: none;
    }

    .input-group {
        display: flex;
    }

    .session-fab {
        display: none;
    }

    .header .button-session {
        opacity: .5;
        pointer-events: none;
    }

    //legacy helper icon buttons
    .image-button {
        display: inline-block;
        overflow: hidden;
        text-indent: -999px;
        background-repeat:no-repeat;
        background-position: center;
        background-size: contain;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0.5rem;
        margin-left: 2rem;
    }

    .image-help {
        @include sb-icon-help($secondary-color);

        &:hover,
        &:focus {
            @include sb-icon-help($anchor-color-hover);
        }
    }
}

.request-status {
    white-space: nowrap;
    &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 6px;
        border-radius: 50%;
        background-color: $light-gray;
    }
    &.pending::before {
        background-color: $yellow;
    }
    &.in_process::before {
        background-color: $green;
    }
    &.action_required::before {
        background-color: $alert-color;
    }
    &.scheduled::before {
        background-color: $blue;
    }
    &.complete::before {
        background-color: $blue;
    }
}

.item-group.request-list {
    .request-list-title {
        @include typography(header-d);
        display: flex;
        justify-content: space-between;
    }
    .request-list-simple {
        @include unstyled-list;
    }
    .request-item {
        flex-wrap: wrap;
        margin-top: $baseline;
    }
    .request-item-title {
        display: block;
    }
    .request-status {
        font-weight: $semibold;
    }

    @include breakpoint(smallplus up) {
        .request-item {
            display: flex;
            margin-top: $baseline / 2;
            &:first-child {
                margin-top: $baseline;
            }
        }
        .request-item-title {
            display: inline-block;
            margin-right: 1em;
            flex-basis: 16em;
            flex-grow: 1;
            flex-shrink: 0;
        }
        .request-status {
            flex-basis: 12em;
            flex-shrink: 0;
        }
    }

    //legacy helper icon buttons
    .image-button {
        display: inline-block;
        overflow: hidden;
        text-indent: -999px;
        background-repeat:no-repeat;
        background-position: center;
        background-size: contain;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 1rem;
        cursor: pointer;
    }

    .image-help {
        @include sb-icon-help($secondary-color);

        &:hover,
        &:focus {
            @include sb-icon-help($anchor-color-hover);
        }
    }
}

.request-list-help {
    padding: $baseline * 3 $baseline * 1.5 $baseline * 1.5;
    max-width: 480px;
    overflow: auto;
    @include typography(text-medium);
    .request-item {
        margin-top: $baseline;
        padding-left: 18px;
    }
    .request-status {
        font-weight: $bold;
        margin-right: .25rem;
        margin-left: -18px;
    }
}
