@import '@foundation/util/value';

@mixin search-form(
    $height: 48px,
    $icon-box-size: $height,
    $icon-size: $height * 2/3,
    $width: 100%,
    $border-radius: 0,
    $baseline: $baseline,
    $text-padding: 1em,
    $inactive-color: rgb(200,200,200),
    $primary-color: $primary-color,
    $secondary-color: $secondary-color,
    $icon-side: right
) {
    .search-cont { //often need the specificity
        .search-label {
            display: none;
            overflow: hidden;
            text-indent: 100%;
            background-size: $icon-size $icon-size;
            background-position: center;
            background-repeat: no-repeat;
            @include sb-icon-search($primary-color);
            &:hover {
                @include sb-icon-search($secondary-color);
            }
        }
        .search-form {
            display: flex;
            position: relative;
            width: $width;
            height: $height;
            @if $icon-side == left {
                flex-direction: row-reverse;
            }
        }
        .search-close {
            @include sb-icon-x($inactive-color);
            display: none;
            position: absolute;
            width: $icon-size * 2/3;
            height: $height;
            padding: 0;
            background-size: $icon-size * 2/3 $icon-size * 2/3;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            text-indent: 100%;
            color: transparent;
            cursor: pointer;
            @if $icon-side == right {
                right: $icon-box-size;
            } @else {
                right: $text-padding;
            }
            &:hover {
                @include sb-icon-x($secondary-color);
            }
        }
        .search-has-text .search-close {
            display: block;
        }
        .search-input {
            width: 100%;
            flex-grow: 1;
            border-radius: $border-radius;
            -webkit-border-radius: $border-radius;
            -webkit-appearance: none;
            @if $icon-side == right {
                padding-left: $text-padding;
                padding-right: calc(#{$text-padding} + #{$icon-box-size});
            } @else {
                padding-left: calc(#{$text-padding} + #{$icon-box-size});
                padding-right: $text-padding;
            }
        }
        .search-submit {
            @include sb-icon-search($inactive-color);
            cursor: default;
            flex-shrink: 0;
            width: $icon-box-size;
            overflow: hidden;
            text-indent: 100%;
            color: transparent;
            background-size: $icon-size $icon-size;
            background-position: center;
            background-repeat: no-repeat;
            @if $icon-side == right {
                margin-left: -$icon-box-size;
            } @else {
                margin-right: -$icon-box-size;
            }
        }
        .search-has-text .search-submit {
            cursor: pointer;
            @include sb-icon-search($primary-color);
            &:hover {
                @include sb-icon-search($secondary-color);
            }
        }
        .sayt-cont {
            z-index: 100;
            top: 100%;
            left: 0;
            right: 0;
            overflow-x: hidden;
            overflow-y: auto;
            ul {
                display: block;
                width: $width;
                list-style: none;
                padding: 0;
                margin: 0;
            }
            .search-result {
                display: block;
            }
            .search-link,
            .search-link a {
                display: block;
            }
            .search-link a,
            .sayt-no-results {
                padding: $baseline $text-padding;
            }
        }
    }
}
//primary-color: collapsed icon base state
//secondary-color: icon hover state
//inactive-color: expanded icon base state
//active-color: expanded icon active state (text entered)
@mixin search-form-collapse(
    $height: 24px,
    $width: 360px,
    $baseline: $baseline / 2,
    $text-padding: $baseline .5em,
    $border-width: 1px,
    $primary-color: false,
    $secondary-color: false,
    $inactive-color: false,
    $active-color: $primary-color,
    $transition-time: .5s,
    $icon-box-size: $height,
    $icon-size: $height,
    $transition-timing-function: ease
) {
    .search-cont {
        width: $height;
        height: $height;
        position: relative;
        .search-form {
            display: block;
            position: absolute;
            right: get-side($text-padding, right) * -1;
            top: get-side($text-padding, top) * -1;
            height: calc(#{$height} + #{get-side($text-padding, top) + get-side($text-padding, bottom)});
            width: auto;
            min-width: $height;
            align-items: center;
        }
        .search-input {
            padding: 0;
            box-sizing: content-box;
            width: $height;
            height: $height;
            margin: $border-width * -1;
            min-height: 0;
            opacity: 0;
            pointer-events: none;
            padding: $text-padding;
            transition: width $transition-time $transition-timing-function,
                        padding $transition-time $transition-timing-function,
                        opacity $transition-time $transition-timing-function;
        }
        .search-close {
            display: block;
            pointer-events: none;
            opacity: 0;
            top: get-side($text-padding, top);
            margin: 0;
            //We need to keep this out of the way for a bit
            // so it doesn't get instantly clicked
            //final position is right: $text-padding
            right: 100%;
            width: $height;
            height: $height;
            transition: none;
            background-size: $icon-size * 2/3 $icon-size * 2/3;
            @if $inactive-color != false {
                @include sb-icon-x($inactive-color);
            }
            @if $secondary-color != false {
                &:hover {
                    @include sb-icon-x($secondary-color);
                }
            }
        }
        .search-submit {
            display: none;
            width: $icon-box-size;
            height: $height;
            position: absolute;
            left: get-side($text-padding, left);
            top: get-side($text-padding, top);
            background-size: $icon-size $icon-size;
            margin: 0;
            @if $inactive-color != false {
                @include sb-icon-search($inactive-color);
            }
        }
        .search-has-text .search-submit {
            @if $active-color != false {
                @include sb-icon-search($active-color);
            }
            @if $secondary-color != false {
                &:hover {
                    @include sb-icon-search($secondary-color);
                }
            }
        }
        .search-label {
            cursor: pointer;
            display: block;
            position: absolute;
            right: get-side($text-padding, right);
            top: get-side($text-padding, top);
            // order: 3;
            width: $height;
            height: $height;
            background-size: $icon-size $icon-size;
            @if $primary-color != false {
                @include sb-icon-search($primary-color);
            }
            @if $secondary-color != false {
                &:hover {
                    @include sb-icon-search($secondary-color);
                }
            }
        }
        .search-expand {
            .search-input {
                pointer-events: auto;
                opacity: 1;
                padding-left: calc(#{get-side($text-padding, left) * 2} + #{$icon-box-size});
                width: calc(#{$width} - #{get-side($text-padding, left) * 2} - #{get-side($text-padding, right)} - #{$icon-box-size});
            }
            .search-submit {
                display: block;
            }
            .search-label {
                opacity: 0;
            }
            .search-close {
                pointer-events: auto;
                opacity: 1;
                right: get-side($text-padding, right);
                transition: opacity $transition-time $transition-time $transition-timing-function,
                            right $transition-time step-end;
            }
        }
        .sayt-cont {
            margin-left: $border-width * -1;
            margin-right: $border-width * -1;
            ul {
                width: $width;
            }
            .search-link a,
            .sayt-no-results {
                padding: $text-padding;
                padding-left: calc(#{get-side($text-padding, left) * 2} + #{$icon-box-size});
            }
        }
    }
}

@mixin search-results {
    .result-count {
        margin-top: $baseline;
    }
    .items {
        @include unstyled-list;
        margin-top: $baseline * 2;
    }
    .item {
        padding-top: $baseline * 1.5;
        padding-bottom: $baseline * 1.5;
        border-top: 1px solid $light-gray;
        & > * {
            margin-top: $baseline;
            &:first-child {
                margin-top: 0;
            }
        }
        .items {
            margin-left: $baseline * 2;
            margin-top: $baseline * 1.5;
        }
        .title {
            @include typography('header-c');
        }
        .item {
            padding-top: $baseline;
            padding-bottom: $baseline;
            .title {
                @include typography('header-e');
            }
        }
        a {
            overflow-wrap: break-word;
        }
    }
}

@mixin search-paging-buttons {
    display: flex;
    padding-top: $baseline;
    margin-top: $baseline * 2;
    border-top: 1px solid $light-gray;
    & > * {
        margin-left: 1rem;
        &:first-child {
            margin-left: 0;
        }
    }
    .disabled {
        opacity: .5;
    }
}
