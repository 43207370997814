$site-width: 1200px !default;
$site-columns: 12 !default;
$outer-gutters: 0 !default;

//2d layouts
@mixin grid-template-columns($columns) {
    -ms-grid-columns: $columns;
    grid-template-columns: $columns;
}
@mixin grid-template-rows($rows) {
    -ms-grid-rows: $rows;
    grid-template-rows: $rows;
}
@mixin grid-column($start, $span: false, $grid12: false) {
    @if $grid12 {
        $start: $start + 1;
    }
    -ms-grid-column: $start;
    @if $span {
        -ms-grid-column-span: $span;
    } @else {
        -ms-grid-column-span: 1;
    }
    @if type-of($span) == number {
        grid-column: #{$start} / span #{$span};
    } @elseif $span {
        grid-column: #{$start} / #{$span};
    } @else {
        grid-column: #{$start};
    }
}
@mixin grid-row($start, $span: false) {
    -ms-grid-row: $start;
    @if $span {
        -ms-grid-row-span: $span;
    } @else {
        -ms-grid-row-span: 1;
    }
    @if type-of($span) == number {
        grid-row: #{$start} / span #{$span};
    } @elseif $span {
        grid-row: #{$start} / #{$span};
    } @else {
        grid-row: #{$start};
    }
}
@mixin grid-justify-self($position) {
    -ms-grid-column-align: $position;
    justify-self: $position;
}
@mixin grid-align-self($position) {
    -ms-grid-row-align: $position;
    align-self: $position;
}

@function ms-repeat($count, $col) {
    @return unquote("(#{$col})[#{$count}]");
}
@mixin grid {
    display: -ms-grid;
    display: grid;
}
@mixin grid-gap($column-gap: false, $row-gap: false) {    
    & > * {
        @if $row-gap {
            @include breakout($row-gap) {
                margin-top: $value / 2;
                margin-bottom: $value / 2;
            }
        }
        @if $column-gap {
            //use padding so margin: auto is still available
            @include breakout($column-gap) {
                padding-left: $value / 2;
                padding-right: $value / 2;
            }
        }
    }
}
@mixin grid14($site-width: $site-width, $outer-gutters: $outer-gutters, $ie11: true, $columns: $site-columns) {
    @include breakout($outer-gutters) {   
        @if $ie11 {
            -ms-grid-columns: minmax($value, 1fr) ms-repeat($columns, minmax(0, #{$site-width / $columns})) minmax($value, 1fr);
        }
        grid-template-columns: minmax($value, 1fr) repeat($columns, minmax(0, #{$site-width / $columns})) minmax($value, 1fr);
    }
    & > * {
        @include grid-justify-self(stretch);
    }
}
@mixin subgrid14($start, $span, $site-width: $site-width, $outer-gutters: $outer-gutters, $root-columns: $site-columns) {
    @include grid-column($start, $span);
    @if ($start == 1) or ($start + $span > $root-columns + 2) {
        @include breakout($outer-gutters) {
            $columns: $span;
            $column-string-start: null;
            $column-string-end: null;
            @if ($start == 1) {
                $column-string-start: minmax(#{$value}, 1fr);
                $columns: $columns - 1;
            }
            @if ($start + $span > $root-columns + 2) {
                $column-string-end: minmax(#{$value}, 1fr);
                $columns: $columns - 1;
            }
            -ms-grid-columns: $column-string-start ms-repeat($columns, minmax(0, #{$site-width / $root-columns})) $column-string-end;
            grid-template-columns: $column-string-start repeat($columns, minmax(0, #{$site-width / $root-columns})) $column-string-end;
        }
    } @else {
        -ms-grid-columns: ms-repeat($span, minmax(0, #{$site-width / $root-columns}));
        grid-template-columns: repeat($span, minmax(0, #{$site-width / $root-columns}));
    }
    & > * {
        @include grid-justify-self(stretch);
    }
}
@mixin subgrid($columns: $site-columns, $root-columns: $site-columns, $site-width: $site-width) {
    -ms-grid-columns: ms-repeat($columns, minmax(0, #{$site-width / $root-columns}));
    grid-template-columns: repeat($columns, minmax(0, #{$site-width / $root-columns}));
    & > * {
        @include grid-justify-self(stretch);
    }
}
@mixin make-grid-rows($item-selectors) {
    @if type-of($item-selectors) == number {
        @for $i from 1 through $item-selectors {
            & > *:nth-child(#{$i}) {
                @include grid-row($i, 1);
            }
        }
    } @else {
        @each $item in $item-selectors {
            $i: index($item-selectors, $item);
            #{$item} {
                @include grid-row($i, 1);
            }
        }
    }
}
