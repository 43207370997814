// @include icon-base($size: 1.25em, $background-size: 1em);
// @include icons($text-color-white, $anchor-color-white-hover);

// .sb-logo-minimal {
//     width: 1em;
//     height: 1em;
//     margin-bottom: -.25em;
//     fill: currentColor;
// }
.site {
    @include sprite-icon();
    .icon-user-add {
        margin-right: -.15em;
        margin-left: .15em;
    }
}
