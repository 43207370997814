//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';

@import 'articles:mixins';
@import 'site:page-mixins';

.layout-campaign-page .page-layout {
    // @include page-layout-article-page;
    .header-banner {
        .pager.page_image img {
            opacity: .2;
        }
    }
    @include breakpoint(xlarge up) {
        .primary {
            .group.page {
                @include grid-row(1);
                @include grid-column(2, 4, true);
                padding-top: $baseline * 5;
            }
            .item-group.campaign-modules {
                @include grid-row(1);
                @include grid-column(7, 6, true);
                margin-bottom: 0;
            }
            padding-bottom: get-breakpoint-value($section-padding, xlarge);
        }
    }
}

.item-group.campaign-modules {
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
    .items {
        margin-top: $baseline * 2;
    }
}
