// -------------------------------------------------------
// Sizes
// -------------------------------------------------------
$em: 16;
$baseline: .75rem; //12px
$gutter: 12px;

$site-width: 1200px;

$content-width: 720px;

//height of sticky header
// $header-height: (
//     small: $baseline * 5,
//     medium: $baseline * 6
// );
$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 768px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1900px,
);
$breakpoint-classes: (small smallplus medium large xlarge xxlarge);

// -------------------------------------------------------
// Colors
// -------------------------------------------------------
$teal: #00A79D;
$teal-dark: #006a5d;
$teal-light: #b9e2e0;
$teal-white: #f0f9f8;

$lime: #CEC42D;
$lime-light: #e7e3a2;
$lime-dark: #9e940d;

$green: #8CC63E;
$green-dark: #5a881c;

$orange: #F16521;
$purple: #91268F;
$yellow: #FEC10D;
$red: #e23f17;
$red-light: #ef8e77;
$blue: #0066AB;

$gray50: #f8f8f8;
$gray100: #f0f0f0;
$gray200: #e5e5e5;
$gray300: #d4d4d4;
$gray400: #b0b0b0;
$gray500: #8f8f8f;
$gray600: #686868;
$gray700: #545454;
$gray800: #363636;
$gray900: #161616;

$white: #fff;
$off-white: $gray100;
$light-gray: $gray300;
$gray: $gray400;
$medium-gray: $gray600;
$dark-gray: $gray800;
$black: #000;

$primary-color: $teal;
$secondary-color: $orange;

$alert-color: $red;
$required-color: $red;

$text-color-white: $white;
$text-color-light: $light-gray;
$text-color-regular: $dark-gray;
$text-color-dark: $black;

$anchor-color: $teal;
$anchor-color-hover: $teal-dark;

$anchor-color-white: $text-color-white;
$anchor-color-white-hover: $teal-light;

$body-font-family: sans-serif; 
$header-font-family: sans-serif;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
