//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';

@import 'site:page-mixins';

.page-layout-usage-report {
    .content,
    .item-root,
    .page-layout {
        display: flex;
    }
    .page-layout {
        flex-direction: column;
    }
    .header-banner {
        .hero-page,
        .date-select,
        .organization-select,
        .banner-links {
            @include grid-column(1, 12, true);
            @include breakpoint(xxlarge up) {
                @include grid-column(2, 10, true);
            }
        }
        .hero-page {
            margin-bottom: 0;
        }
        .organization-select {
            @include grid-row(3);
            margin-top: $baseline;
            @include breakout($content-gutters) {
                margin-left: $value;
                margin-right: $value;
            }
            @include breakout($section-padding, medium) {
                margin-bottom: $value;
            }
            @include breakpoint(medium up) {
                @include grid-row(2, 2);
                @include grid-column(8, 5, true);
                @include grid-align-self(flex-end);
                margin-top: $baseline * 2;
            }
            @include breakpoint(large up) {
                @include grid-justify-self(flex-end);
                min-width: 350px;
            }
            @include breakpoint(xxlarge up) {
                @include grid-column(8, 4, true);
            }
        }
        .date-select {
            @include grid-row(4);
            margin-top: $baseline;
            @include breakout($section-padding) {
                margin-bottom: $value;
            }
            @include breakout($content-gutters) {
                margin-left: $value;
                margin-right: $value;
            }
            @include breakpoint(medium up) {
                @include grid-row(3);
                @include grid-column(1, 7, true);
            }
            @include breakpoint(xxlarge up) {
                @include grid-column(2, 5, true);
            }
        }
        .banner-links {
            @include grid-row(5);
            @include breakout($content-gutters) {
                margin-left: $value;
                margin-right: $value;
            }
            margin-bottom: $baseline * 2;
        }
    }
    .primary {
        flex-grow: 1;
    }
    .date-select {
        @include typography(header-b);
        display: flex;
        line-height: 1;
        .icon {
            font-size: .75em;
        }
        .icon-calendar {
            font-size: 1em;
        }
        .icon-arrow-left {
            transform: translateX(-.05em);
        }
        .icon-arrow-right {
            transform: translateX(.05em);
        }
        .icon-arrow-double-right {
            transform: translateX(.05em);
        }
    }
    .date-select-date {
        flex-basis: 16ch;
    }
    .date-select-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 1.5em;
        height: 1.5em;
        margin: -.25em 0;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color .2s;
        &:hover {
            background-color: $gray200;
        }
        &:disabled {
            opacity: .5;
            background-color: transparent;
        }
        @media print {
            visibility: hidden;
        }
    }
    .primary > * {
        @include grid-column(1, 14);
        margin: 0;
    }
}
