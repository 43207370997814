.group {
    & > * {
        margin-top: $baseline * 2;
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 2.5;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    .logo {
        img {
            height: #{36/$em}rem;
            @include breakpoint(xlarge up) {
                height: #{60/$em}rem;
            }
        }
    }
    .description {
        @include typography(text-large);
    }
    .button-cont {
        margin-top: $baseline * 2.5;
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 3;
        }
    }
}

.group.page {
    max-width: $content-width;
    // @include breakout($section-padding) {
    //     margin-top: $value;
    // }
    @include breakout($content-gutters) {
        & > * {
            margin-left: $value;
            margin-right: $value;
        }
    }
}

.primary .item-wrapper {
    // @include grid;
    // @include grid14;
    // @include make-grid-rows(2);
    & > * {
        // @include header-columns();
        margin-top: $baseline;
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 2;
        }
        // @include breakout($content-gutters) {
        //     margin-left: $value;
        //     margin-right: $value;
        // }
        &:first-child {
            margin-top: 0;
        }
    }
    .title {
        @include typography(header-b);
        max-width: 30ch;
    }
    .description {
        @include typography(text-large);
        max-width: 60ch;
    }
    &:empty {
        display: none;
    }
}

.pager > .item-wrapper {
    .title {
        @include typography(header-d);
        max-width: 30ch;
    }
    .description {
        max-width: 30ch;
        margin-top: $baseline / 2;
        @include breakpoint(xlarge up) {
            margin-top: $baseline;
        }
    }
    margin-bottom: $baseline;
    @include breakpoint(xlarge up) {
        margin-bottom: $baseline * 1.5;
    }
}

.item-group.pagination-page {
    @include pagination;
    @include grid-justify-self(end);
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
}

.header-banner {
    @include hero-banner;
}
.layout-home-home {
    .group .description {
        // max-width: 30ch;
    }
    .header-banner {
        @include hero-banner-height($banner-height-home);
    }
}

.button-cont.session-fab {
    @include session-fab;
}

.item-group.sitemap {
    @include sitemap-basic;
    @include breakout($section-padding) {
        padding-top: $value;
        padding-bottom: $value;
    }
}

.controller.secondary-navigation {
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
    a {
        @include typography(text-large);
        display: block;
        color: inherit;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .is-active a {
        color: $anchor-color;
    }
}

.dropdown-menu {
    @include dropdown-menu;
}

.controller.breadcrumbs {
    font-size: 20rem/$em;
    font-weight: $semibold;
    ul {
        display: flex;
        flex-wrap: wrap;
    }
    li:not(:last-child)::after {
        content: '/';
        margin-left: .5em;
        margin-right: .5em;
    }
}
